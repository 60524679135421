<template>
  <div class="inner" :style="styleObject" :class="{ loading: store.getters['modal/getLoadingState']() }">
    <div class="auto">
      <div v-show="store.getters['modal/getLoadingState']()" class="loader">
        <LoadingSpinner />
      </div>
      <div class="modal-container" :class="{ hideComponent: store.getters['modal/getLoadingState']() }">
        <div class="custom-header-wrapper">
          <slot name="custom-header">
            <ModalHeader v-if="!noHeader" v-bind="store.state.modal.modalState?.props" @close-action="closeAction" />
          </slot>
        </div>
        <div class="custom-component-wrapper">
          <slot name="custom-component" />
        </div>
        <div class="custom-footer-wrapper">
          <slot name="custom-footer">
            <ModalFooter v-if="!noFooter" v-bind="store.state.modal.modalState?.props" @close-action="closeAction" />
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import ModalHeader from './ModalHeader.vue'
import ModalFooter from './ModalFooter.vue'
import { CustomModalProps } from '@/interfaces'
import { onMounted, reactive } from 'vue'

const store = useStore()
const props = defineProps<CustomModalProps>()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styleObject = reactive<any>({})
const emit = defineEmits(['close-action'])

onMounted(() => {
  structureStyleObject()
})

const closeAction = async () => {
  emit('close-action')
}

function structureStyleObject() {
  if (props.height) {
    styleObject.height = props.height
  }
  if (props.width) {
    styleObject.width = props.width
  }
  if (props.padding) {
    styleObject.padding = props.padding
  }
  if (props.margin) {
    styleObject.margin = props.margin
  }
  if (props.maxWidth) {
    styleObject.maxWidth = props.maxWidth
  }
  if (props.maxHeight) {
    styleObject.maxHeight = props.maxHeight
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.inner {
  background: #ffffff;
  border-radius: 1rem;
  position: relative;
  color: black !important;
  justify-content: center;
  align-items: center;
  max-height: 100vh;

  &.loading {
    .auto {
      display: flex;
      justify-content: center;
      align-items: center;

      .loader {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .hideComponent {
    opacity: 0;
    pointer-events: none;
  }
  .modal-container {
    max-height: 95vh;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 3rem;
    .custom-header-wrapper {
      flex-shrink: 0;
    }
    .custom-footer-wrapper {
      flex-shrink: 0;
    }
    .custom-component-wrapper {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 12px;
    }
  }
}
</style>
