<template>
  <div ref="parent" class="container">
    <div v-for="category in categoriesToShow" :key="category.id">
      <Tag
        :label="category.name" :font-size="12" :show-close="false" :show-img="false"
        :tag-colors="tagStyle"
      />
    </div>
    <TeleportTooltip v-if="categoriesHidden.length > 0" :placement="'top'" :hover="true" class="tooltip">
      <div :class="isOnVideoThumb ? 'more-with-circle' : 'more'">+{{ categoriesHidden.length }}</div>
      <template #content>
        <div class="selector-background" :class="{ wide: categoriesHidden.length > 3 }">
          <div v-for="category in categoriesHidden" :key="category.id" class="item">
            {{ category.name }}
          </div>
        </div>
      </template>
    </TeleportTooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { categoriesOverflow, OverflowType } from '@/composables'
import { TeleportTooltip } from '@/components/common'
import i18n from '@/i18n'
import Tag from '@/components/common/tag/Tag.vue'
import { TagColors } from '@/components/common/tag/utils'

const props = defineProps({
  categories: Array,
  isOnVideoThumb: {
    type: Boolean,
    default: false
  }
})
const parent = ref('parent')
const { checkOverflowInit, categoriesToShow, categoriesHidden } = categoriesOverflow()
const tagStyle = computed(() => (props.isOnVideoThumb ? TagColors.Black : TagColors.Grey))

function initOverflow() {
  checkOverflowInit(
    parent.value,
    props.categories.map((c) => {
      return { name: i18n.global.t('category_' + c), id: c.id }
    }),
    OverflowType.Horizontal,
    20
  )
}

watch(() => props.categories, initOverflow)
onMounted(initOverflow)
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}
.label {
  white-space: nowrap;
  padding: 2px 8px;
  width: fit-content;
  border-radius: 4px;
  border: 1px solid $white;
  background: $categoriesLabelBackground;
  font-size: 12px;

  &.on-video-label {
    border-radius: 2px;
    padding: 3px 8px;
    font-size: 10px;
    background: $titleBlack;
  }
}

.item {
  background: $categoriesItemBackground;
  border: 1px solid $inputBorder;
  border-radius: 4px;
  margin: 4px;
  padding: 2px 20px;
  text-transform: capitalize;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  height: fit-content;
  /* body text */

  color: $bodyText;
  opacity: 1;
  white-space: nowrap;

  img {
    margin-right: 8px;
  }
}

.more {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
}

.more-with-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $titleBlack;
  border: 1px solid $white;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  padding: 0;
}
.tooltipcategory {
  div {
    height: 18px;
  }
}
</style>
