<template>
  <label class="switch">
    <input
      id="sound" type="checkbox" :checked="switchState" :disabled="disabled"
      @change="toggle"
    >
    <span :class="['toggle', color]" />
    <!-- <span v-show="switchState" class="on-text">{{ $t('on') }}</span>
    <span v-show="!switchState" class="off-text">{{ $t('off') }}</span> -->
  </label>
</template>

<script setup lang="ts">
import { SwitchColors } from '@/interfaces'
import { onMounted, ref, watch } from 'vue'

const props = defineProps({ state: { type: Boolean }, disabled: { type: Boolean, default: false }, color: { type: String, default: SwitchColors.Green } })
const switchState = ref(false)
const emit = defineEmits(['toggle'])

function toggle(e: Event) {
  const target = e.target as HTMLInputElement
  emit('toggle', target.checked)
}

onMounted(() => {
  switchState.value = props.state
})

watch(
  () => props.state,
  () => {
    switchState.value = props.state
  }
)
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  width: 40px;
  cursor: pointer;
}

.switch input[type='checkbox'] {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  bottom: 0;
  top: -1px;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  height: 16px;
  width: 40px;
}

.on-text,
.off-text {
  position: absolute;
  display: inline-block;
  top: 51%;
  transform: translateY(-38%);
  padding: 0 6px;
  font-size: 10px;
  font-family: Nunito;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  color: white;
}

.on-text {
  left: 0;
}

.off-text {
  right: 0;
}

.switch input[type='checkbox']:checked + .toggle.blue {
  background-color: $switchToggleBackgroundBlue;
}

.switch input[type='checkbox']:checked + .toggle.green {
  background-color: $switchToggleBackgroundGreen;
}

.switch input[type='checkbox']:checked + .toggle:before {
  transform: translateX(26px);
}

.toggle::before {
  width: 10px;
  height: 10px;
  top: 3px;
}
</style>
