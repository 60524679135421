<template>
  <div class="no-data-container">
    <img :src="getImg || img" alt="">
    <div class="text-content">
      <div v-if="msgs[which]" class="title">{{ msgs[which] }}</div>
      <div v-if="subtitleMsgs[which] && showContent" class="subtitle">{{ subtitleMsgs[which] }}</div>
    </div>
    <div v-if="multipleBtns[which] && showContent" class="nd-buttons">
      <button v-for="(btn, index) of multipleBtns[which]" :key="index" class="secondary" @click="() => (btn.route !== 'close' ? router.push(btn.route) : emit('close'))">
        {{ btn.txt }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import PackageCreationIntroSVG from '@/assets/content/PackageCreationIntro.svg'
import NoSearchResults from '@/assets/library/video/noresults.svg'
import { useRouter } from 'vue-router'
import { DataType, NoDataBtn } from '@/interfaces'
import { isAgencyOwner } from '@/services/utils'

const emit = defineEmits(['close'])

interface NoDataProps {
  which: DataType | string // which type
  img?: string
}
const props = withDefaults(defineProps<NoDataProps>(), {
  img: PackageCreationIntroSVG,
  disableButton: false
})
const router = useRouter()
const { t } = useI18n()
const showContent = computed(() => !excludeForAgencyDomains())
const getImg = computed(() => (props.which === 'noSearchResults' || props.which === 'reports' ? NoSearchResults : PackageCreationIntroSVG))
const msgs = reactive<Record<DataType | string, string>>({
  noSearchResults: t('library-no-videos-found'),
  packages: t('content-package-start-creating'),
  videos: t('content-video-start-creating'),
  playlists: t('playlist-start-creating'),
  organizations: t('organization-start-creating'),
  activeOrganizations: t('dashboard-agency-noaccountsfound'),
  widgets: t('widget-start-creating'),
  domains: t('domains-start-creating'),
  excludedDomains: t('excluded-domains-start-creating'),
  publisherProposals: t('publisher-packages-not-available'),
  proposals: t('publisher-packages-not-available'),
  streams: t('content-streams-start-creating'),
  contentTargeting: t('paired-urls-no-data'),
  personalChannelVideos: t('empty-channel-videos'),
  emptyFolder: t('content-folder-view-no-data'),
  reports: t('report-empty-table'),
  generalNoData: t('side-container-no-data'),
  createPackageNoData: t('side-container-no-data'),
  folderGeneralNoData: t('side-container-no-data'),
  emptyPackageContent: t('content-package-no-data'),
  emptyChannel: t('side-container-no-data')
})
const subtitleMsgs = reactive<Record<DataType | string, string>>({
  excludedDomains: t('excluded-domains-start-creating-subtitle'),
  streams: t('content-streams-start-creating-subtitle'),
  videos: t('content-video-start-creating-subtitle'),
  packages: t('content-package-start-creating-subtitle'),
  playlists: t('playlist-start-creating-subtitle'),
  organizations: t('organization-start-creating-subtitle'),
  widgets: t('widgets-start-creating-subtitle'),
  domains: t('domains-start-creating-subtitle'),
  contentTargeting: t('paired-urls-no-data-start-creating-subtitle'),
  personalChannelVideos: t('content-channel-video-start-creating-subtitle')
})
const multipleBtns = reactive<Record<string, NoDataBtn[]>>({
  emptyFolder: [
    {
      txt: t('content-upload-videos'),
      route: '/videos/videos/add'
    },
    {
      txt: t('content-upload-from-stream'),
      route: '/videos/videos/addstream'
    }
  ],
  packages: [
    {
      txt: t('content-add-package'),
      route: '/packages/packages/add'
    }
  ],
  videos: [
    {
      txt: t('content-add-video'),
      route: '/videos/videos/add'
    }
  ],
  streams: [
    {
      txt: t('content-add-stream'),
      route: '/videos/videos/addstream'
    }
  ],
  personalChannelVideos: [
    {
      txt: t('content-add-video'),
      route: '/videos/videos/add'
    }
  ],
  playlists: [
    {
      txt: t('playlists-create-playlist'),
      route: '/library/createplaylist?new=true'
    }
  ],
  widgets: [
    {
      txt: t('widgets-create-new'),
      route: '/widgets/create-widget-picker'
    }
  ],
  organizations: [
    {
      txt: t('accounts-add-account'),
      route: '/accounts/add'
    }
  ],
  domains: [
    {
      txt: t('domains-add-domain-button'),
      route: '/inventory/publisher-domains/add'
    }
  ],
  excludedDomains: [
    {
      txt: t('domains-add-excluded-domain-button'),
      route: '/inventory/excluded-domains/add'
    }
  ],
  generalNoData: [
    {
      txt: t('back'),
      route: 'close'
    }
  ],
  createPackageNoData: [
    {
      txt: t('back'),
      route: '/packages'
    }
  ],
  folderGeneralNoData: [
    {
      txt: t('back'),
      route: '/videos/folders/'
    }
  ]
})

function excludeForAgencyDomains(): boolean {
  return isAgencyOwner() && props.which === DataType.MyDomains
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.nd-buttons {
  display: flex;
  flex-direction: row;
  & .secondary {
    width: 220px;
  }
}

.secondary {
  margin-top: 32px;
}

.no-data-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #7d81b3;
  margin-top: 16px;
  margin-bottom: 32px;
  .msg {
    opacity: 0.5;
  }
  img {
    width: 80%;
    max-height: 170px;
    margin-bottom: 24px;

    @media (max-height: $md) {
      width: 50%;
      max-height: 100px;
    }
  }

  .text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .title {
    color: $titleBlack;
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 0.1px;

    @media (max-height: $md) {
      font-size: 18px;
    }
  }

  .subtitle {
    color: $bodyText;
    font-weight: 600;
  }

  .learn-more {
    span {
      font-weight: 500;
      font-size: 16px;
    }
    a {
      margin-inline-start: 5px;
      color: $primary-color;
    }
  }
}
</style>
