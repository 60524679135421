<template>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div class="modal-wrapper" aria-modal="true" role="dialog" tabindex="-1">
        <ModalBase ref="modalContainer" v-bind="store.state.modal.modalState?.props" @close-action="onCloseModal" @keydown.esc.stop.prevent="onCloseModal">
          <template v-if="store.state.modal.modalState?.componentHeader" #custom-header>
            <component :is="store.state.modal.modalState?.componentHeader" v-bind="store.state.modal.modalState?.props" />
          </template>
          <template v-if="store.state.modal.modalState?.componentContent" #custom-component>
            <component :is="store.state.modal.modalState?.componentContent" v-bind="store.state.modal.modalState?.props" />
          </template>
          <template v-if="store.state.modal.modalState?.componentFooter" #custom-footer>
            <component :is="store.state.modal.modalState?.componentFooter" v-bind="store.state.modal.modalState?.props" />
          </template>
        </ModalBase>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import ModalBase from './modal-structure/ModalBase.vue'
import { markRaw, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { openModalCustomConfirmation } from '@/services/utils'
import { CRUD, DataType } from '@/interfaces'
import { ModalType } from '@/components/modals/modals-util'
import { IModalState } from '@/store/custom-modal.module'
const modalContainer = ref()
const store = useStore()

onClickOutside(modalContainer, async (e) => {
  if (store.state.modal.modalType === ModalType.DISCARD_CHANGES) return

  const target = e.target as HTMLElement
  if (target.className === 'modal-wrapper') {
    await onCloseModal()
  }
})

function cloneOriginalModal(originalModalState: IModalState): IModalState {
  return {
    ...originalModalState,
    modalState: {
      ...originalModalState.modalState,
      componentContent: originalModalState.modalState.componentContent ? markRaw(originalModalState.modalState.componentContent) : null,
      componentHeader: originalModalState.modalState.componentHeader ? markRaw(originalModalState.modalState.componentHeader) : null,
      componentFooter: originalModalState.modalState.componentFooter ? markRaw(originalModalState.modalState.componentFooter) : null,
      props: { ...originalModalState.modalState.props },
      payload: { ...originalModalState.modalState.payload }
    },
    loading: originalModalState.loading,
    modalType: originalModalState.modalType
  }
}

function isPayloadEmpty(obj: Record<string, unknown>): boolean {
  return Object.values(obj).every((value) => {
    if (Array.isArray(value)) {
      return value.length === 0
    }
    return !value
  })
}

async function onCloseModal() {
  if (!isPayloadEmpty(store.state.modal.modalState.payload) && store.state.modal.modalState.props.showDiscardStep) {
    const originalModal = cloneOriginalModal(store.state.modal)
    openModalCustomConfirmation({ action: CRUD.ConfirmClose, type: DataType.Folder, onCancel: () => reopenModal(originalModal), onConfirm: closeModal }, ModalType.DISCARD_CHANGES)
  } else {
    await closeModal()
  }
}

async function reopenModal(originalModal: IModalState) {
  store.commit('modal/openModal', { ...originalModal.modalState, modalType: originalModal.modalType, payload: originalModal.modalState.payload })
  focusOnFirstInputEl()
}

async function closeModal() {
  const modalProps = store.state.modal.modalState.props
  if (typeof modalProps.onCancel === 'function') {
    await modalProps.onCancel({ ...modalProps })
  }
  await store.dispatch('modal/closeModal')
}

function focusOnFirstInputEl() {
  nextTick(() => {
    const modalElement = modalContainer.value?.$el
    if (modalElement) {
      const firstInput = modalElement.querySelector('input')
      if (firstInput) {
        firstInput.focus()
      }
    }
  })
}

onMounted(() => {
  // hide overflow if needed
  const body = document.body
  if (body) body.style.overflow = 'hidden'

  focusOnFirstInputEl()
})

onUnmounted(() => {
  // reset overflow to auto
  const body = document.body
  if (body) body.style.overflow = 'auto'
})
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;

  z-index: $zIndex999999;

  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.564);
  backdrop-filter: blur(3px);

  display: grid;
  place-items: center;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: 0.25s ease all;
}
</style>
