import { CRUD, DataType } from '@/interfaces'
import { checkPermission } from '@/store/permissions'
import { RouteRecordRaw } from 'vue-router'

const widgetsRoutes: RouteRecordRaw[] = [
  {
    path: 'edit/:id',
    name: 'editwidget',
    component: () => import('@/components/widgets/edit-widget/WidgetEdit.vue'),
    meta: {
      title: 'widgets'
    },
    children: [
      {
        path: 'confirm-click-to-play',
        name: 'confirm-click-to-play',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'widgets'
        },
        props: { type: DataType.Widget, action: CRUD.Confirm }
      },
      {
        path: 'delete',
        name: 'edit-deletewidget',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'widgets'
        },
        props: { type: DataType.Widget, action: CRUD.Delete }
      },
      {
        path: 'deactivate',
        name: 'edit-deactivatewidget',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'widgets'
        },
        props: { type: DataType.Widget, action: CRUD.Deactivate }
      },
      {
        path: 'closeunsaved',
        name: 'edit-closeUnsavedWidget',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'widgets'
        },
        props: { type: DataType.Widget, action: CRUD.ConfirmClose }
      },
      {
        path: 'confirm-frequency-cap',
        name: 'confirm-frequency-cap',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'widgets'
        },
        props: { type: DataType.Widget, action: CRUD.Confirm }
      }
    ]
  },
  {
    path: 'embed/:id',
    name: 'embedwidget',
    component: () => import('@/components/widgets/WidgetEmbed.vue'),
    meta: {
      title: 'widgets'
    }
  },
  {
    path: 'congratulations/:id',
    name: 'widgets-congratulations',
    component: () => import('@/components/widgets/WidgetCongratulations.vue'),
    props: true,
    meta: {
      title: 'widgets'
    }
  },
  {
    path: 'create-widget-picker',
    name: 'widgets/create-widget-picker',
    component: () => import('@/components/widgets/WidgetCreatePicker.vue'),
    beforeEnter: (to, from, next) => checkPermission('widgets', next),
    meta: {
      title: 'widgets'
    }
  },
  {
    path: 'create-widget-floater/:orientation',
    name: 'create-widget-floater',
    component: () => import('@/components/widgets/WidgetCreate.vue'),
    beforeEnter: (to, from, next) => checkPermission('widgets', next),
    meta: {
      title: 'widgets',
      fullscreen: true
    },
    children: [
      {
        path: 'confirm-click-to-play',
        name: 'confirm-click-to-play-new-floater',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'widgets'
        },
        props: { type: DataType.Widget, action: CRUD.Confirm }
      },
      {
        path: 'confirm-frequency-cap',
        name: 'confirm-frequency-cap-new-floater',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'widgets'
        },
        props: { type: DataType.Widget, action: CRUD.Confirm }
      }
    ]
  },
  {
    path: 'create-widget-in-stream/:orientation',
    name: 'create-widget-in-stream',
    component: () => import('@/components/widgets/WidgetCreate.vue'),
    beforeEnter: (to, from, next) => checkPermission('widgets', next),
    meta: {
      title: 'widgets',
      fullscreen: true
    },
    children: [
      {
        path: 'confirm-click-to-play',
        name: 'confirm-click-to-play-new-instream',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'widgets'
        },
        props: { type: DataType.Widget, action: CRUD.Confirm }
      },
      {
        path: 'confirm-frequency-cap',
        name: 'confirm-frequency-cap-new-instream',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'widgets'
        },
        props: { type: DataType.Widget, action: CRUD.Confirm }
      }
    ]
  }
]

export default widgetsRoutes
