<template>
  <div class="container">
    <div ref="textField" :class="{ expanded, faded: !expanded && shouldTruncate, text }">
      {{ props.text }}
    </div>
    <button v-if="shouldTruncate" class="toggle-button" :class="{ lowered: expanded }" @click="toggleText">
      {{ expanded ? $t('read-less') : $t('read-more') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'

const props = defineProps(['text'])
const expanded = ref(false)
const textField = ref<HTMLElement | null>(null)
const shouldTruncate = ref(false)

onMounted(() => {
  const textEl = textField.value
  if (textEl) {
    shouldTruncate.value = textEl.scrollHeight > textEl.clientHeight
  } else {
    shouldTruncate.value = false
  }
})

function toggleText() {
  expanded.value = !expanded.value
  if (textField.value && !expanded.value) {
    textField.value.scrollTop = 0
  }
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
}
.text {
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  scroll-behavior: smooth;
  --mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  &.faded {
    mask: var(--mask);
  }
}

.expanded {
  max-height: fit-content;
  white-space: normal;
}
.toggle-button {
  background: white;
  border: none;
  cursor: pointer;
  color: #659ed7;
  text-decoration: none;
  margin: 0;
  padding: 0;
  width: auto;
  font-weight: 600;
  position: absolute;
  right: 0;
  bottom: 14px;
  height: max-content;
  padding: 0 0 0 10px;

  &:hover {
    color: $primary-color;
  }
}

.lowered {
  bottom: -10px;
}
</style>
