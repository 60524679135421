<template>
  <div ref="dataViewRow" class="widget">
    <div v-if="props.item" class="row-content grid" :style="`grid-template-columns:  ${columnsGridTemplate(props.columns)};`">
      <div class="widget-name" :class="shouldHideOnBreakpoint(props.columns, 'widget_name')" @click="editWidget">
        <Tooltip :placement="'top'" :content="props.item.widget_name" auto-ellipsis />
      </div>
      <div class="name" :class="shouldHideOnBreakpoint(props.columns, 'widget_id')" @click="editWidget">
        <TeleportTooltip placement="top" :content="props.item.widget_id + ' '" :text-length="20">
          <div>
            {{ props.item.widget_id + ' ' }}
          </div>
        </TeleportTooltip>
      </div>
      <div class="type" :class="shouldHideOnBreakpoint(props.columns, 'widget_type')" @click="editWidget">
        <img v-if="props.item.type && props.item.type.includes('floater')" src="@/assets/widgets/floater.svg">
        <img v-if="props.item.type && !props.item.type.includes('floater')" src="@/assets/widgets/instream.svg">{{
          $t('widgets-' + props.item.type.toLowerCase().replace('_', '-'))
        }}
      </div>
      <div class="flex" :class="shouldHideOnBreakpoint(props.columns, 'widgets-device-type')" @click="editWidget">
        <div v-if="props.item.is_desktop_active" class="device-type">
          {{ $t('widget-type-desktop') }}
        </div>
        <div v-if="props.item.is_mobile_active" class="device-type">
          {{ $t('widget-type-mobile') }}
        </div>
      </div>
      <div class="name" :class="shouldHideOnBreakpoint(props.columns, 'create_date')" @click="editWidget">
        {{ getDateString(props.item.create_date) }}
      </div>
      <div class="p-2" :class="shouldHideOnBreakpoint(props.columns, 'status')">
        <div class="activation active" :class="{ inactive: !props.item.is_active }" @click="editWidget">
          {{ $t(props.item.is_active ? 'widgets-active' : 'widgets-inactive') }}
        </div>
      </div>
      <ActionButtons :actions="filteredActions" :is-visible="isRowHovered" :class="shouldHideOnBreakpoint(props.columns, 'actions')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { getDateString, openModalCustomConfirmation } from '@/services/utils'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import { useElementHover } from '@vueuse/core'
import { computed, inject, ref } from 'vue'
import { TeleportTooltip, Tooltip } from '@/components/common'
import { CRUD, DataActions, DataType } from '@/interfaces'
import { apiService } from '@/services'
import store from '@/store'
import { useI18n } from 'vue-i18n'
import { Widget } from '@/services/widget/types'
import { WIDGET_ACTIONS_INJECTION_KEY } from '@/constants'
import { ModalType } from '@/components/modals/modals-util'

const props = defineProps(['item', 'columns'])
const router = useRouter()
const { t } = useI18n()
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const modalTypeDuplicate = ModalType.WIDGET_DUPLICATE_MODAL
const modalTypeDelete = ModalType.WIDGET_DELETE_MODAL
const dataActions: DataActions | undefined = inject<DataActions>(WIDGET_ACTIONS_INJECTION_KEY)
const actions = [
  {
    label: 'edit',
    handler: editWidget,
    translationKey: 'edit'
  },
  {
    label: 'duplicate',
    handler: openDuplicateWidgetModal,
    translationKey: 'playlist-duplicate-title'
  },
  {
    label: 'embed',
    dropDownLabel: 'embedMenu',
    handler: embedWidget,
    translationKey: 'library-video-embed'
  },
  {
    label: 'delete',
    handler: openDeleteWidgetModal,
    translationKey: 'delete'
  }
]
const filteredActions = computed(() => {
  return props.item.is_active ? actions : actions.filter((action) => action.label !== 'embed')
})

const duplicateWidget = async ({ inputValue }: { inputValue: string }) => {
  const duplicateWidget: Widget = {
    ...props.item,
    widget_name: inputValue
  }
  const response = await apiService.widget.postWidget(duplicateWidget)
  if (response) {
    store.dispatch('messages/addMessage', {
      message: t('widget-duplication-message-success'),
      type: 'success'
    })
    if (dataActions?.clearSearch) dataActions.clearSearch()
    if (dataActions?.resetToDefaultPageAndGetData) dataActions.resetToDefaultPageAndGetData()
  }
}
const deleteWidget = async () => {
  return apiService.widget.deleteWidget(props.item.widget_id).then(() => {
    if (dataActions?.clearSearch) dataActions.clearSearch()
    store.dispatch('messages/addMessage', {
      message: t('widget-deleted'),
      type: 'success'
    })
  })
}

function editWidget() {
  router.push(`/widgets/edit/${props.item.widget_id}`)
}

function openDuplicateWidgetModal() {
  openModalCustomConfirmation(
    { action: CRUD.Duplicate, type: DataType.Widget, itemName: props.item.widget_name, id: props.item.widget_id, onConfirm: duplicateWidget },
    modalTypeDuplicate
  )
}
function embedWidget() {
  router.push({ path: `/widgets/embed/${props.item.widget_id}` })
}
function openDeleteWidgetModal() {
  openModalCustomConfirmation({ action: CRUD.Delete, type: DataType.Widget, itemName: props.item.widget_name, onConfirm: deleteWidget }, modalTypeDelete)
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.activation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 20px;

  width: 97px;
  height: 28px;

  border-radius: 4px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.1px;
  text-transform: capitalize;

  /* dark gray */

  /* secondary */
  &.active {
    width: 115px;
    height: 28px;

    background: rgba(66, 200, 160, 0.1);
    border-radius: 4px;
    color: #42c8a0;
  }

  &.inactive {
    width: 115px;
    height: 28px;

    background: #f2f2f2;
    border-radius: 4px;

    color: #6f6e71;
  }
}

.widget {
  .row-content {
    cursor: pointer;
    align-items: center;
    font-style: normal;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.1px;
    color: #4c4c66;
    box-sizing: border-box;
    text-overflow: ellipsis;
    height: auto;
    position: relative;

    > div {
      padding: 1rem;
    }
  }

  @media (max-width: $xl) {
    height: auto;
    padding: 10px 20px;
  }

  @media (max-width: $lg) {
    height: auto;
    padding: 10px 20px;
  }

  @media (max-width: $md) {
    height: auto;
    padding: 10px 20px;
  }

  .device-type {
    border: 1px solid $inputBorder;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $bodyText;
    opacity: 0.7;
    padding: 4px 12px;
    margin-right: 8px;
    white-space: nowrap;
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    white-space: nowrap;
  }

  .widget-name {
    display: grid;
    grid-template-columns: 100%;
    overflow: hidden;
  }

  &:hover {
    background-color: #f3f4fa;
  }

  .type {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      margin: 0 8px 0 0;
      transform: translateY(-1px);
    }
  }
}
</style>
