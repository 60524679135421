<template>
  <div ref="folderElement" class="folder-wrapper" :class="{'with-categories': packageView}">
    <div class="folder-container">
      <div class="icon-wrapper">
        <IconWrapper :icon-height="30" name="folder" />
      </div>
      <div class="folder-details">
        <div class="folder-details-top">
          <div class="folder-title">
            <Tooltip
              class="title" :class="{ 'hovered-folder': isFolderHovered }" :placement="'top'" :content="folder.name"
              auto-ellipsis
            />
          </div>
          <template v-if="packageView">
            <slot name="add-button" />
          </template>
          <ActionButtons
            v-else :actions="actions" :view-type="DataViewType.Grid" class="action-buttons"
            :class="{ 'is-visible': isFolderHovered }"
          />
        </div>
        <div class="folder-info">
          <span class="nowrap num-of-videos">{{ folder.number_of_videos }} {{ $t('content-videos') }}</span>
          <span class="bullet-separator">●</span>
          <span class="create-date">{{ formatDateDateMonthYear(folder.create_date!) }}</span>
        </div>
        <VideoComponentCategories v-if="withCategories" :categories="getFolderCategories()" class="categories" :class="cSize.breakpointsAdditive" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconWrapper from '@/components/common/icons/IconWrapper.vue'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { CRUD, DataType, DataViewType } from '@/interfaces'
import { Folder } from '@/services/folder/types'
import { formatDateDateMonthYear, openModalCustomConfirmation } from '@/services/utils'
import { ModalType } from '@/components/modals/modals-util'
import { useRouter } from 'vue-router'
import { Tooltip } from '@/components/common'
import {useElementHover} from "@vueuse/core";
import {reactive, ref} from "vue";
import {componentSize} from "@/composables";
import VideoComponentCategories from "@/components/base/videoComponent/VideoComponentCategories.vue";

const props = defineProps<{ folder: Folder, packageView: boolean, withCategories?: boolean }>()
const emit = defineEmits(['deleteFolder'])
const folderElement = ref()
const isFolderHovered = useElementHover(folderElement)
const cSize = reactive(componentSize())

const router = useRouter()

const actions = [
  {
    label: 'edit',
    handler: openEditModal,
    translationKey: 'edit'
  },
  {
    label: 'delete',
    handler: openDeleteModal,
    translationKey: 'delete'
  }
]

function openDeleteModal() {
  const modalType = ModalType.ADD_FOLDER_MODAL
  openModalCustomConfirmation({ action: CRUD.Delete, type: DataType.Folder, onConfirm: () => emit('deleteFolder', props.folder) }, modalType)
}

function openEditModal() {
  router.push({
    name: 'edit-folder',
    params: { id: props.folder.id }
  })
}

function getFolderCategories() {
  const categoriesSet = new Set()
  props.folder.videos?.forEach((video) => {
    video.categories?.forEach((category) => {
      categoriesSet.add(category)
    })
  })
  return Array.from(categoriesSet)
}
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';

.folder-wrapper {
  border-radius: 6px;
  border: 1px solid $inputBorder;
  width: 315px;
  padding: 1rem;
  cursor: pointer;
  @include hoverFolderEffect;
  .folder-container {
    display: flex;
    gap: 1rem;
    .icon-wrapper {
      display: inline-block;
      border-radius: 4px;
      background: $iconWrapperBackground;
      padding: 6px;
      align-self: flex-start;
    }
    .folder-details {
      flex: 1;
      .folder-details-top {
        width: 100%;
        display: flex;
        font-size: 18px;
        font-weight: 700;
        .folder-title {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          .title {
            max-width: 230px;
            &.hovered-folder {
              max-width: 220px;
            }
          }
        }
        .action-buttons {
          flex: 0;
          padding: 0;
          visibility: hidden;
          &.is-visible {
            visibility: visible;
          }
        }
      }
      .create-date {
        font-size: 14px;
        font-weight: 400;
      }
      .folder-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        > span {
          font-size: 12px;
        }
      }
      .categories {
        margin-top: 0.75rem;
        height: 22px;
        max-width: 240px;
      }
    }
  }
  .action-buttons {
    :deep(.dropdown-img) {
      filter: invert(1);
    }
  }
}
</style>
