export enum TagColors {
  Black = 'black',
  Grey = 'grey',
  White = 'white'
}

export enum TagSizes {
  Small = 'small',
  Big = 'big'
}
