<template>
  <div class="dropdown-container-ce">
    <Popper
      v-bind="$attrs"
      open-delay="20"
      close-delay="0"
      :offset-distance="offsety"
      :offset-skid="offsetx"
      :placement="position"
      @close:popper="open(false)"
      @open:popper="open(true)"
    >
      <div class="dropdown-trigger" :class="{ hover: hoverActive }">
        <slot name="trigger" />
      </div>
      <template #content="slotProps">
        <div class="slotcontainer-outer" @click.self="slotProps.close">
          <div class="slotcontainer" @mouseleave="closeOnHover && slotProps.close()">
            <slot name="content" v-bind="slotProps" />
          </div>
        </div>
      </template>
    </Popper>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Popper from 'vue3-popper'

const emit = defineEmits(['opened'])
const hoverActive = ref(false)
withDefaults(
  defineProps<{
    offsetx?: string
    offsety?: string
    closeOnHover?: boolean,
    position?:
      | 'auto'
      | 'bottom'
      | 'auto-start'
      | 'auto-end'
      | 'top'
      | 'top-start'
      | 'top-end'
      | 'bottom-start'
      | 'bottom-end'
      | 'right'
      | 'right-start'
      | 'right-end'
      | 'left'
      | 'left-start'
      | 'left-end'
  }>(),
  {
    offsetx: '0',
    offsety: '8',
    position: 'bottom-end',
    closeOnHover: true
  }
)

function open(opened: boolean) {
  hoverActive.value = opened
  emit('opened', opened)
}

defineExpose({
  open
})
</script>

<style lang="scss" scoped>

.dropdown-container-ce {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & .hover {
    button {
      &.secondary {
        box-shadow: 0px 0px 14px 0px #fb2265 !important;
      }

      &.primary {
        box-shadow: 0px 0px 14px 0px #2555ff !important;
      }

      img {
        transform: rotateX(180deg) !important;
      }
    }
  }
}

.slotcontainer {
  padding: 0.5rem 1rem;
}

:deep(.popper) {
  position: absolute !important;
  margin: 0px !important;
  width: max-content !important;
}

:deep(.inline-block) {
  position: relative !important;
  // P.S : To remove yellow margin add in your component:
  // margin: 0 !important;
  // border: none !important;
}
</style>

<style lang="scss">
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #4c4c66;
  --popper-theme-border-width: 0px;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.dropdown-container-ce {
  .popper {
    --popper-theme-padding: 2px 3px;
    & div.dropdown-item {
      margin-bottom: 16px !important;
    }

    div.dropdown-item:last-child {
      margin-bottom: 0 !important;
    }

    .dropdown-line {
      margin: 16px 0 16px 0 !important;
      width: 100% !important;
      height: 1px !important;
      background-color: #e8ebf4 !important;
    }

    .dropdown-item {
      display: flex !important;
      justify-content: left !important;
      align-items: center !important;
      font-family: Nunito !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      line-height: 19px !important;
      letter-spacing: 0.10000000149011612px !important;
      text-align: left !important;
      color: #4c4c66 !important;
      cursor: pointer !important;

      &:hover:not(.disabled-with-tooltip) {
        background-color: $dropdownChangeIndicator;
      }

      img.dropdown-item-icon {
        margin-right: 8px !important;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
