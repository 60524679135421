import qs from 'qs'
import store from '@/store'
import i18n from '@/i18n'
import type { PostStream, PostVideo, Stream, Streams, StreamsFolders, UploadVideoRequest, Video, VideoFilters, VideoIn, Videos, VideoUpdate } from '@/services/videos/types'
import { DataViewFilters } from '@/services/types'
import api from '@/services/api'
import axios, { AxiosError } from 'axios'
import type { Folder } from '@/services/folder/types'

const getRecommended = async (): Promise<Videos> => {
  const a = await api.get<Videos>('/videos/recommended')
  return a.data
}
const getVideos = async (filters: VideoFilters): Promise<Videos> => {
  const a = await api.get<Videos>('/videos', {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
  return a.data
}
const getVideo = async (video_id: string): Promise<Video> => {
  try {
    const a = await api.get<VideoIn>('/video', { params: { video_id } })
    return a.data.video
  } catch (error) {
    return Promise.reject(error)
  }
}
const putVideo = async (video: VideoUpdate): Promise<Video> => {
  try {
    const a = await api.put<Video>('/video', video)
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('content-video-update-successfully-toast'),
      type: 'success'
    })
    return a.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return undefined
  }
}
const deleteVideo = async (video_id: string) => {
  const a = await api.delete<Video>(`/video/${video_id}`)
  return a.data
}
const postVideo = async (video: PostVideo): Promise<true | AxiosError> => {
  try {
    await api.post('/video', video)
    return true
  } catch (error) {
    console.log('success', error)
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return error as AxiosError
  }
}
const getRelatedVideos = async (video_id: string): Promise<Video[]> => {
  try {
    const a = await api.get('/video/related', { params: { video_id } })
    return a.data.videos
  } catch (error) {
    return Promise.reject(error)
  }
}
const getVideoMultiple = async (video_ids: string[]): Promise<Video[]> => {
  const requests = []
  video_ids.forEach((video_id) => {
    requests.push(api.get('/video', { params: { video_id } }))
  })

  const a = await axios.all(requests)
  return a.map((x) => {
    return x.data.video
  })
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestVideoUpload = async (filename: string): Promise<UploadVideoRequest | any> => {
  // fake

  const videoRequest = await api.post<UploadVideoRequest>('/video/request_upload', {
    filename
  })
  return videoRequest.data
  // return { id: filename, signed_url: 'link', public_url: 'ling' }
}
const getStreams = async (params: DataViewFilters) => {
  const a = await api.get<Streams>('/video/streams', {
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
  return a.data
}
const getStream = async (id: number) => {
  const a = await api.get<Stream>(`/video/stream?id=${id}`)
  return a.data
}
const deleteStream = async (id: string) => {
  try {
    const a = await api.delete<Stream>(`/video/stream/${id}`)
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('stream-delete-success'),
      type: 'success'
    })
    return a.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return false
  }
}
const postStream = async (stream: PostStream): Promise<boolean> => {
  try {
    await api.post<Streams>('/video/stream', stream)
    return true
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return false
  }
}
const putStream = async (stream: PostStream, isDeactivated?: boolean) => {
  try {
    const a = await api.put<Stream>('/video/stream', stream)
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('stream-update-success'),
      type: 'success'
    })
    if (isDeactivated) {
      store.dispatch('messages/addMessage', {
        message: i18n.global.t('import-deactivated'),
        type: 'success'
      })
    }
    return a.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return false
  }
}

const getStreamsFolders = async (): Promise<Folder[]> => {
  const result = await api.get<StreamsFolders>('/video/streams/folders')
  return result.data.folders
}

export default {
  getRecommended,
  getVideo,
  getVideos,
  putVideo,
  deleteVideo,
  postVideo,
  getRelatedVideos,
  getVideoMultiple,
  requestVideoUpload,
  getStreams,
  getStream,
  deleteStream,
  postStream,
  putStream,
  getStreamsFolders
}
