<template>
  <div
    ref="dataViewRow"
    :class="['domain', 'grid', { highlighted: isRefreshing }]"
    :style="`grid-template-columns: ${columnsGridTemplate(props.columns)};`"
    @click.stop="openAdsTxt(item)"
  >
    <div v-if="isAgency" class="name" :class="shouldHideOnBreakpoint(props.columns, 'organization_name')">
      <Tooltip :placement="'top'" :content="item.organization_name" auto-ellipsis />
    </div>
    <div class="name" :class="shouldHideOnBreakpoint(props.columns, 'domain')">
      <Tooltip :placement="'top'" :content="item.domain" auto-ellipsis />
    </div>
    <div
      class="status ml-2"
      :class="{
        ...shouldHideOnBreakpoint(props.columns, 'system_status'),
        approved: item.system_status === 'approved',
        pending: item.system_status === 'pending',
        rejected: item.system_status === 'rejected'
      }"
    >
      <div>{{ $t('settings-' + item.system_status) }}</div>
    </div>
    <div class="date" :class="shouldHideOnBreakpoint(props.columns, 'create_date')">{{ getDateString(item.create_date) }}</div>
    <div
      class="adstxt-status"
      :class="{
        ...shouldHideOnBreakpoint(props.columns, 'settings-status'),
        green: item?.ads_txt?.missing?.length === 0,
        red: item?.ads_txt?.missing?.length > 0
      }"
    >
      <img v-if="item?.ads_txt?.missing?.length === 0" src="@/assets/content/checkmark.svg">
      <img v-else src="@/assets/content/alert-red.svg">

      <span v-if="item?.ads_txt?.missing !== null && item?.ads_txt?.existing !== null">
        {{ item?.ads_txt?.existing?.length }} / {{ item?.ads_txt?.missing?.length + item?.ads_txt?.existing?.length }}</span>
      <div v-else>{{ EMPTY_FIELD }}</div>
    </div>
    <div class="buttons" :class="shouldHideOnBreakpoint(props.columns, 'actions')">
      <!-- <img src="@/assets/content/domains.svg" /> -->
      <button class="ads" @click.stop="openAdsTxt(item)">{{ $t('inventory-my-domains-get-ads.txt-btn') }}</button>
      <ActionButtons :actions="actions" :is-visible="isRowHovered || isRefreshing" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject } from 'vue'
import { useElementHover } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import type { Domain } from '@/services/domain/types'
import { debounce, getDateString, isAgencyOwner } from '@/services/utils'
import { Tooltip } from '@/components/common'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { apiService } from '@/services'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import { openModalCustomConfirmation } from '@/services/utils'
import { ModalType } from '@/components/modals/modals-util'
import { CRUD, DataActions, DataType } from '@/interfaces'
import { useI18n } from 'vue-i18n'
import { DOMAINS_ACTIONS_INJECTION_KEY } from '@/constants'
import { EMPTY_FIELD } from '@/services/constants'

const props = defineProps(['item', 'columns'])
const emit = defineEmits(['refreshRow'])
const router = useRouter()
const { t } = useI18n()
const isRefreshing = ref(false)
const isAgency = computed(() => isAgencyOwner())
const store = useStore()
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const modalType = ModalType.DOMAINS_DELETE_DOMAIN_MODAL
const dataActions = inject<DataActions>(DOMAINS_ACTIONS_INJECTION_KEY)

const onRefreshDebounced = debounce(() => {
  refreshDataRow()
}, 250)
const actions = [
  {
    label: 'refresh',
    handler: onRefreshDebounced,
    translationKey: 'refresh',
    get isLoading() {
      return isRefreshing.value
    }
  },
  ...(!isAgency.value
    ? [
        {
          label: 'delete',
          handler: openDeleteDomain,
          translationKey: 'delete'
        }
      ]
    : [])
]

function refreshDataRow() {
  if (isRefreshing.value) {
    return
  }
  isRefreshing.value = true
  apiService.domain.refreshDomain(props.item.id, props.item?.organization_id).then((updatedItem) => {
    if (updatedItem) {
      emit('refreshRow', updatedItem)
      setTimeout(() => {
        isRefreshing.value = false
        store.dispatch('messages/addMessage', { message: t('ads-txt-update-sucess'), type: 'error' })
      }, 1500)
    }
  })
}

function openDeleteDomain() {
  openModalCustomConfirmation({ action: CRUD.Delete, type: DataType.MyDomains, onConfirm: deleteDomain }, modalType)
}

function deleteDomain() {
  apiService.domain
    .deleteDomain(props.item.domain, store.state.user.user.organization_id)
    .then(() => {
      store.dispatch('messages/addMessage', {
        message: t('domain-deleted'),
        type: 'success'
      })
      if (dataActions?.refreshData) dataActions.refreshData()
    })
    .catch((error) => {
      store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    })
}

function openAdsTxt(domain: Domain) {
  if (isAgency.value) {
    router.push({
      path: `/domains-inventory/view/${domain.id}/${domain.organization_id}`
    })
  } else {
    router.push({
      path: `/inventory/publisher-domains/view/${domain.id}`
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.content {
  margin-top: 2em;
  height: fit-content;
  padding-bottom: 20px;
}

.top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2em;

  .add-domain {
    margin: 0 30px;
  }

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #8a898c;
  }
}

.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.green {
  color: #42c8a0 !important;
}

.red {
  color: #ff3737 !important;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    margin-left: 10px;
  }
}

.top-bar {
  display: flex;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #c2c6cc;
    margin: 0 10px 0 10px;
    cursor: pointer;
    position: relative;

    .line {
      display: none;
      position: absolute;
    }

    &.selected {
      color: #4c4c66;
      font-weight: 700;

      .line {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #2c95ff;
      }
    }
  }
}

.button-bar {
  display: grid;
  grid-template-columns: auto 213px 263px;
  align-items: center;
  margin-top: 0px;

  .search-buttons {
    display: flex;
    align-items: center;
    padding: 0 30px;

    .filter {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: #9599c6;
      display: flex;
      cursor: pointer;
      width: 100px;
      margin: 0 30px 0 10px;
      position: relative;

      img {
        margin: 0 5px 0 5px;
      }

      .filterbox {
        width: 408px;
        height: 210px;
        position: absolute;
        background: #ffffff;
        border-radius: 8px;
        filter: drop-shadow(10px 12.6px 29px rgba(67, 66, 89, 0.1));
        z-index: $zIndex12;
        top: calc(100% + 5px);
      }
    }
  }

  button {
    width: 190px;
    justify-self: end;
  }

  .edit {
    background: none;
    width: 180px;
    padding: 0;
  }
}

.button-bar {
  display: grid;
  grid-template-columns: auto 213px 263px;
  align-items: center;
  margin-top: 0px;

  .search-buttons {
    display: flex;
    align-items: center;

    .filter {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: #9599c6;
      display: flex;
      cursor: pointer;
      width: 100px;
      margin: 0 30px 0 10px;
      position: relative;

      img {
        margin: 0 5px 0 5px;
      }

      .filterbox {
        width: 408px;
        height: 210px;
        position: absolute;
        background: #ffffff;
        border-radius: 8px;
        filter: drop-shadow(10px 12.6px 29px rgba(67, 66, 89, 0.1));
        z-index: $zIndex12;
        top: calc(100% + 5px);
      }
    }
  }

  button {
    width: 190px;
    justify-self: end;
  }

  .edit {
    background: none;
    width: 180px;
    padding: 0;
  }
}

.search-input {
  width: 350px;
  position: relative;

  input {
    padding-left: 40px;
    border-radius: 30px;
  }

  img {
    position: absolute;
    top: 9px;
    left: 12px;
    cursor: pointer;
  }
}

.line {
  height: 1px;
  padding: 0 30px;
  margin: 0 30px;
  background-color: #dadbe8;
}

.grid {
  display: grid;
}

.legend {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.2px;

  /* body text */

  color: #4c4c66;
  margin-bottom: 20px;
}

.highlighted {
  background: #f3f4fa;
}

.domain {
  align-items: center;

  &:hover {
    background: #f3f4fa;
  }

  & > div {
    padding-left: 1rem;
  }

  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #4c4c66;
    opacity: 0.8;
  }

  .name {
    font-weight: 600;
    font-size: 16px;
    display: grid;
    grid-template-columns: 100%;
    overflow: hidden;

    img {
      margin-right: 12px;
      width: 18px;
      height: 18px;
    }
  }

  .adstxt-status {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .status {
    width: 115px;
    height: 32px;

    background: #f2f2f2;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    padding: 0;

    letter-spacing: 0.1px;

    /* dark gray */

    color: #6f6e71;
    display: flex;
    align-items: center;
    justify-content: center;

    &.approved {
      border-radius: 4px;
      background: rgba(66, 200, 160, 0.1);
      color: var(--status, #42c8a0);
    }

    &.rejected {
      border-radius: 4px;
      background: rgba(253, 237, 238, 1);
      color: var(--status, #ff3737);
    }
  }

  .trash {
    justify-self: center;
    cursor: pointer;
    margin-right: 20px;

    &:hover {
      filter: $truvid-filter-effect;
    }
  }

  .ads {
    width: 157px;
    height: 32px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: var(--primary, #2c95ff);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid var(--primary, #2c95ff);
    background: rgba(50, 89, 255, 0.1);
    transition:
      background 0.3s ease,
      color 0.3s ease;

    &:hover {
      background: #2c95ff;
      color: #f2f2f2;
    }

    img {
      height: 14px;
      width: 14px;
      margin-right: 5px;
    }
  }
}
</style>
