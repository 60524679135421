import store from '@/store'
import { pl2pl } from '@/services/utils'
import type { Playlist, PlaylistInput, Playlists, PlaylistsFilters, PlaylistsInput } from '@/services/playlist/types'
import api from '@/services/api'
import { AxiosResponse } from 'axios'

const getPlaylists = async (filters: PlaylistsFilters): Promise<Playlists> => {
  const playlists: Playlists = { playlists: [], total: 0 }
  const a = await api.get<PlaylistsInput>('/playlists', { params: filters })
  if (a.data?.playlists) {
    playlists.total = a.data.total ?? 0
    a.data.playlists.forEach((pl) => {
      playlists.playlists.push(pl2pl(pl))
    })
  }
  return playlists
}
const getPlaylist = async (playlist_id: number): Promise<Playlist> => {
  try {
    const a = await api.get<PlaylistInput>('/playlist', { params: { playlist_id } })
    return pl2pl(a.data)
  } catch (error) {
    return Promise.reject(error)
  }
}
const postPlaylist = async (playlist: Playlist): Promise<{ playlist_id: number }> => {
  try {
    const response = await api.post<{ playlist_id: number }>('/playlist', playlist)
    return response.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return { playlist_id: -1 }
  }
}

const postPlaylists = async (playlists: Playlist[]): Promise<boolean> => {
  const requests: Promise<AxiosResponse<Playlist>>[] = []
  playlists.forEach((pl) => {
    pl.videos_list = JSON.parse(JSON.stringify(pl.videos_list))
    requests.push(api.post<Playlist>('/playlist', pl))
  })
  try {
    await Promise.all(requests)
    return true
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return false
  }
}

const putPlaylist = async (playlist: Playlist): Promise<boolean> => {
  try {
    await api.put<Playlist>('/playlist', { ...playlist })
    return true
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return false
  }
}

const putPlaylists = async (playlists: Playlist[]): Promise<boolean> => {
  const requests: Promise<AxiosResponse<Playlist>>[] = []
  playlists.forEach((pl) => {
    pl.videos_list = JSON.parse(JSON.stringify(pl.videos_list))
    requests.push(api.put<Playlist>('/playlist', pl))
  })
  try {
    await Promise.all(requests)
    return true
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return false
  }
}

const deletePlaylist = async (id: number): Promise<boolean> => {
  await api.delete<Playlist>(`/playlist/${id}`)
  return true
}

export default {
  getPlaylists,
  getPlaylist,
  postPlaylist,
  postPlaylists,
  putPlaylist,
  putPlaylists,
  deletePlaylist
}
