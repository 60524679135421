<template>
  <div class="player-modal-container">
    <div class="tgrid">
      <div class="row">
        <div class="col-12 flex-center">
          <div v-if="!isLoading" class="video">
            <VideoPlayer :video="video" />
          </div>
        </div>
        <div class="col-12 d-flex flex-column">
          <Tooltip placement="top-start" :text-length="50">
            <div v-if="!isLoading" class="date">
              {{ formatDateDateMonthYear(video.upload_date) }}
            </div>
            <template #content>
              <div class="style-font">{{ video.title }}</div>
            </template>
          </Tooltip>
          <Tooltip placement="top-start" :text-length="50">
            <div class="title">
              {{ video.title }}
            </div>
            <template #content>
              <div class="style-font">{{ video.title }}</div>
            </template>
          </Tooltip>
          <VideoComponentCategories :categories="video.categories" class="categoriescomponent" />
          <ShowMoreLess v-if="video.description.length >= 1" class="description mt-1" :text="video.description" />
          <div class="author mt-2">
            <div class="general">
              <img :src="videoProfileImage" alt="">
              <TeleportTooltip placement="top-start" :content="video.organization_name">
                <div class="author-title">
                  <div class="name">
                    {{ video.organization_name }}
                  </div>
                </div>
              </TeleportTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { formatDateDateMonthYear } from '@/services/utils'
import VideoPlayer from '@/components/common/VideoPlayer.vue'
import { TeleportTooltip, Tooltip } from '@/components/common'
import ShowMoreLess from '@/components/common/ShowMoreLess/ShowMoreLess.vue'
import defaultProfile from '@/assets/common/fake_profile.png'
import store from '@/store'
import { useVideoData } from '@/composables'
import VideoComponentCategories from '@/components/base/videoComponent/VideoComponentCategories.vue'

const props = store.getters['modal/getModalExtraProps']()

const { video, isLoading } = useVideoData(props.id)
const videoProfileImage = computed(() => video.organization_profile_image ?? defaultProfile)

onMounted(async () => {})

watch(isLoading, async (isLoadingStatus) => {
  if (!isLoadingStatus) {
    await store.dispatch('modal/stopLoading')
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.player-modal-container {
  min-width: 40vw;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.title {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  word-break: break-all;
  color: $bodyText;
}

.description {
  color: $bodyText;
  font-size: 14px;
  font-weight: 400;
}

.video {
  cursor: pointer;
  position: relative;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $black;
  border-radius: 10px;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.date {
  text-align: start;
  color: $bodyText;
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0 8px 0;
}

.categories {
  display: flex;
  margin: 16px 0 8px 0;

  > div {
    margin-right: 5px;
    text-align: center;
    text-transform: capitalize;
    border-radius: 4px;
    background: $categoriesLabelBackground;
    box-sizing: border-box;
    min-width: 80px;
    width: fit-content;
    padding: 2px 20px;
    color: $titleBlack;
    font-size: 14px;
    font-weight: 500;
  }
}

.author {
  .general {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 24px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 33px;
      margin-right: 16px;
    }

    .author-title {
      max-width: 100%;
      overflow: hidden;

      .name {
        color: $inputColor;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
</style>
