<template>
  <div class="label-container" :class="[props.color, props.size, { 'with-icon': !!props.iconName }]">
    <IconWrapper v-if="iconName" class="icon" :name="iconName" />
    <span class="nowrap">{{ props.text }}</span>
  </div>
</template>

<script setup lang="ts">
import IconWrapper from '@/components/common/icons/IconWrapper.vue'
import { LabelColors, LabelSizes } from '@/components/common/label/utils'

interface LabelProps {
  text: string
  color?: LabelColors
  size?: LabelSizes
  iconName?: string
}

const props = withDefaults(defineProps<LabelProps>(), {
  color: LabelColors.GreyWhite,
  size: LabelSizes.Large
})
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';

.label-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-weight: 500;
  border-radius: 4px;
  gap: 0.25rem;

  &.large {
    height: 1.75rem;
    font-size: 0.875rem;
    padding: 0 0.625rem;

    &.with-icon {
      padding-left: 0.313rem;
    }
  }

  &.medium {
    height: 1.5rem;
    font-size: 0.75rem;
    padding: 0 0.375rem;

    &.with-icon {
      padding-left: 0.1875rem;
    }
  }

  &.small {
    height: 1.25rem;
    font-size: 0.6875rem;
    padding: 0 0.5rem;

    &.with-icon {
      padding-left: 0.25rem;
      gap: 0.125rem;
    }
  }

  .icon {
    height: 0.9rem;
    width: 0.9rem;
  }
}

.blue {
  background: $primary-color-50;
  color: $primary-color;
}

.green {
  background: $green-50;
  color: $green-500;
}

.grey {
  background: $grey-100;
  color: $grey-500;
}

.red {
  background: $error-red-50;
  color: $error-red-500;
}

.purple {
  background: $purple-50;
  color: $purple-500;
}

.turquoise {
  background: $turquoise-50;
  color: $turquoise-500;
}

.yellow {
  background: $warning-yellow-100;
  color: $warning-yellow-500;
}

.grey-white {
  background: $white;
  color: $grey-500;
  border: 1px solid $display-neutrals-300;
}
</style>
