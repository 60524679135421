<template>
  <img :src="icon" :style="iconStyle" alt="icon" class="icon">
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import * as icons from '@/components/common/icons/index'

interface Props {
  name: string
  iconHeight?: number
}

const props = defineProps<Props>()
const icon = computed(() => icons[props.name as keyof typeof icons])

const iconStyle = computed(() => {
  const style: Record<string, string> = {}

  if (props.iconHeight) {
    style.height = `${props.iconHeight}px`
  }
  return style
})
</script>

<style lang="scss" scoped>
.icon {
  vertical-align: middle;
}
</style>
