import { Component } from 'vue'
import { Commit } from 'vuex'
import { CustomModalProps } from '@/interfaces'
import { ModalType } from '@/components/modals/modals-util'

// in case i18n is needed in the future...
// import i18n from '@/i18n'
// i18n.global.t('some-translation', { someParam: someValue })

export type VueComponentContent = Component
export type VueComponentHeader = Component
export type VueComponentFooter = Component

export interface IModalProps {
  modalType: ModalType | null
  componentContent?: VueComponentContent | null
  componentHeader?: VueComponentHeader | null
  componentFooter?: VueComponentFooter | null
  props?: CustomModalProps
  payload?: Record<string, unknown>
}

export interface IModalState {
  modalType: ModalType | null
  modalState: {
    componentContent: VueComponentContent | null
    componentHeader: VueComponentHeader | null
    componentFooter: VueComponentFooter | null
    props: CustomModalProps
    payload: Record<string, unknown>
  }
  loading: boolean
}

const basicState = {
  componentContent: null,
  componentHeader: null,
  componentFooter: null,
  props: {},
  payload: {}
}

export const modal = {
  namespaced: true,
  state: (): IModalState => ({
    modalState: basicState,
    loading: false,
    modalType: null
  }),
  mutations: {
    openModal(state: IModalState, modalPayload: IModalProps) {
      const { props, modalType, componentContent, componentHeader, componentFooter, payload } = modalPayload
      state.loading = false
      state.modalType = modalType
      state.modalState = {
        componentContent: componentContent || null,
        componentHeader: componentHeader || null,
        componentFooter: componentFooter || null,
        props: props || {},
        payload: payload || {}
      }
    },

    closeModal(state: IModalState) {
      state.modalState = basicState
      state.modalType = null
    },

    startLoading(state: IModalState) {
      state.loading = true
    },

    stopLoading(state: IModalState) {
      state.loading = false
    },

    setActionValidation(state: IModalState, payload: boolean) {
      if (state.modalState.props.footerProps) {
        state.modalState.props.footerProps.isConfirmValid = payload
      }
    },

    setModalProps(state: IModalState, payload: Record<string, unknown>) {
      state.modalState.props = { ...payload }
    },
    setModalPayload(state: IModalState, payload: Record<string, unknown>) {
      state.modalState.payload = { ...payload }
    }
  },
  actions: {
    openModal({ commit }: { commit: Commit }, payload: IModalProps) {
      commit('openModal', payload)
    },
    closeModal({ commit }: { commit: Commit }) {
      commit('closeModal')
    },
    startLoading({ commit }: { commit: Commit }) {
      commit('startLoading')
    },
    stopLoading({ commit }: { commit: Commit }) {
      commit('stopLoading')
    },
    setActionValidation({ commit }: { commit: Commit }, payload: boolean) {
      commit('setActionValidation', payload)
    },
    setModalPayload({ commit }: { commit: Commit }, payload: Record<string, unknown>) {
      commit('setModalPayload', payload)
    }
  },
  getters: {
    getLoadingState: (state: IModalState) => () => {
      return state.loading
    },
    getModalType: (state: IModalState) => () => {
      return state.modalType
    },
    getModalProps: (state: IModalState) => () => {
      return state.modalState.props
    },
    getModalPayload: (state: IModalState) => () => {
      return state.modalState.payload
    },
    getModalExtraProps: (state: IModalState) => () => {
      return state.modalState.props.extraProps
    }
  }
}
