import type { Creator, FilterField, LibraryFilter } from '@/interfaces'
import type { Language, Languages } from '@/services/general/types'
import type { VideoCategories, VideoCategory } from '@/services/types'
import type { Video } from '@/services/videos/types'
import type { Package } from '@/services/content/package/types'
import { TRUVID_MAX_FILTER_VIDEO_LENGTH } from '@/services/constants'
import { Folder, Folders } from '@/services/folder/types'

export interface Library {
  filters: LibraryFilter
  languages: Languages
  categories: VideoCategories
  videos: Video[]
  currentPageNumber: number
  categoriesArray: VideoCategory[]
  languagesArray: Language[]
  packages: Package[]
  creators: Creator[]
  folders: Folders
}

export const library = {
  namespaced: true,
  state: (): Library => ({
    filters: {
      packages: [],
      ownership: false,
      is_private: false,
      show_packages: false,
      show_folders: false,
      show_public: false,
      duration: [0, TRUVID_MAX_FILTER_VIDEO_LENGTH],
      categories: [],
      creatorId: -1,
      search: '',
      languages: [],
      sortBy: '',
      sortOrder: '',
      results_per_page: 0,
      page_number: 1,
      startDate: '',
      endDate: '',
      pricing: '',
      uploadDate: 'all',
      orientation: 'all',
      folderId: 0
    },
    languages: { languages: [] },
    categories: { categories: [] },
    packages: [],
    creators: [],
    folders: { folders: [], total: 0 },
    currentPageNumber: 1,
    categoriesArray: [],
    languagesArray: [],
    videos: []
  }),
  mutations: {
    setPackagesFilter(state, packages: number[]) {
      state.filters.packages = packages
    },
    setOwnership(state, isOwn: boolean) {
      state.filters.ownership = isOwn
    },
    setPrivacy(state, isPrivate: boolean) {
      state.filters.is_private = isPrivate
    },
    setIsShowPublic(state, isShowPublic: boolean) {
      state.filters.show_public = isShowPublic
    },
    setIsShowPackages(state, isShowPackages: boolean) {
      state.filters.show_packages = isShowPackages
    },
    setIsShowFolders(state, isShowFolders: boolean) {
      state.filters.show_folders = isShowFolders
    },
    setDurationFilter(state, duration: number[]) {
      state.filters.duration = duration
    },
    setCurrentPageNumber(state, page: number) {
      state.currentPageNumber = page
    },
    setCategories(state, categories: number[]) {
      state.filters.categories = categories
    },
    setCreator(state, creator: number) {
      state.filters.creatorId = creator
    },
    setFolderFilter(state, id: number) {
      state.filters.folderId = id
    },
    setSearch(state, search: string) {
      state.filters.search = search
    },
    setUploadStartDate(state, startDate: string) {
      state.filters.startDate = startDate
    },
    setUploadEndDate(state, startDate: string) {
      state.filters.endDate = startDate
    },
    setLanguages(state, languages: number[]) {
      state.filters.languages = languages
    },
    setSort(state, sort: { sortBy: string; sortOrder: string }) {
      state.filters.sortBy = sort.sortBy
      state.filters.sortOrder = sort.sortOrder
    },
    setPricing(state, pricing: string) {
      state.filters.pricing = pricing
    },
    setUploadDate(state, date: string) {
      state.filters.uploadDate = date
    },
    setOrientationFilter(state, orientation: string) {
      state.filters.orientation = orientation
    },
    setAvailableCategoriesAndLanguagesAndCreatorsAndPackages(
      state: Library,
      obj: { categories: VideoCategories; languages: Languages; creators: Creator[]; packages: Package[]; folders: Folders }
    ) {
      state.categories = obj.categories
      state.languages = obj.languages
      state.creators = obj.creators
      state.packages = obj.packages
      state.folders = obj.folders
    },
    setVideos(state, videos: Video[]) {
      state.videos = videos
    },
    setCategoriesArray(state, categories: VideoCategory[]) {
      state.categoriesArray = categories
    },
    setPackagesArray(state, packages: VideoCategory[]) {
      state.packgaesArray = packages
    },
    setLanguagesArray(state, languages: Language[]) {
      state.languagesArray = languages
    },
    selectVideo(state, video: Video) {
      state.selectedVideos.push(video)
    },
    setSelectVideos(state, videos: Video[]) {
      state.selectedVideos = videos
    },
    deselectAllVideos(state) {
      state.selectedVideos = []
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFilter(state, payload: { filterName: FilterField; value: any }) {
      const { filterName, value } = payload
      state.filters[`${filterName as string}`] = value
    },
    updateVideo(state, payload: { index: number; updatedVideo: Video }) {
      state.videos.splice(payload.index, 1, payload.updatedVideo)
    },
    logout(state) {
      state.filters = {
        duration: [0, TRUVID_MAX_FILTER_VIDEO_LENGTH],
        categories: [],
        creatorId: -1,
        is_private: false,
        ownership: false,
        show_packages: false,
        show_folders: false,
        show_public: false,
        search: '',
        languages: [],
        sortBy: '',
        sortOrder: '',
        results_per_page: 0,
        page_number: 1,
        startDate: '',
        endDate: '',
        pricing: '',
        uploadDate: '',
        folderId: 0
      }
      state.languages = { languages: [] }
      state.categories = { categories: [] }
      state.creators = { creators: [] }
      state.currentPageNumber = 1
      state.videos = []
      state.categoriesArray = []
      state.languagesArray = []
    },
    resetFilters(state: Library) {
      state.filters.duration = [0, TRUVID_MAX_FILTER_VIDEO_LENGTH]
      state.filters.categories = []
      state.filters.languages = []
      state.filters.packages = []
      state.filters.ownership = false
      state.filters.is_private = false
      state.filters.show_packages = false
      state.filters.show_folders = false
      state.filters.show_public = false
      state.filters.page_number = 1
      state.filters.startDate = ''
      state.filters.endDate = ''
      state.filters.pricing = ''
      state.filters.orientation = 'all'
      state.filters.uploadDate = 'all'
      state.filters.folderId = 0
    }
  },
  getters: {
    getFolders: (state: Library) => (): Folder[] => {
      return state.folders.folders
    },
    getFolderFilter: (state: Library) => (): number => {
      return state.filters.folderId
    },
    getShowFolders: (state: Library) => (): boolean => {
      return state.filters.show_folders
    },
    getShowPackages: (state: Library) => (): boolean => {
      return state.filters.show_packages
    }
  },
  actions: {}
}
