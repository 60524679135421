export enum LabelColors {
  Green = 'green',
  Grey = 'grey',
  Red = 'red',
  Blue = 'blue',
  Purple = 'purple',
  Turquoise = 'turquoise',
  GreyWhite = 'grey-white',
  Yellow = 'yellow'
}

export enum LabelSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}
