<!-- eslint-disable vue/no-v-html-->
<template>
  <div :id="id" :class="['custom-input', ...getClassList()]">
    <div
      v-if="
        [
          CustomInputType.String as string,
          CustomInputType.Number as string,
          CustomInputType.Float as string,
          CustomInputType.Textarea as string,
          CustomInputType.Multiselect as string,
          CustomInputType.Date as string,
          CustomInputType.Password as string
        ].includes(inputType) &&
          (label || tooltip)
      "
      class="label-container"
    >
      <label v-if="label" class="input-label" :for="customId">
        {{ label }}
        <span v-if="asteriks">*</span>
        <TextIconTooltip v-if="tooltip" :tooltip="tooltip" :placement="infoTooltipPlacement" @click.prevent.stop />
      </label>
      <div v-if="secondaryLabel" class="secondary-label">{{ secondaryLabel }}</div>
    </div>
    <div v-if="[CustomInputType.CheckBoxGroup as string, CustomInputType.RadioGroup as string].includes(inputType) && (label || tooltip)" class="label-container">
      <div v-if="label" class="input-label">
        {{ label }} <span v-if="asteriks"> *</span>
        <TextIconTooltip v-if="tooltip" :tooltip="tooltip" :placement="infoTooltipPlacement" />
      </div>
      <div v-if="secondaryLabel" class="secondary-label">{{ secondaryLabel }}</div>
    </div>
    <div v-if="description">
      <div class="description-container">{{ description }}</div>
    </div>
    <CustomString
      v-if="inputType === CustomInputType.String && !localLoad"
      :model-value="inputValueLocal"
      :placeholder="placeholder"
      :valid="v$.$errors.length === 0"
      :tooltip="tooltip"
      :label="label"
      :icon-pre="iconPre"
      :icon-action="iconAction"
      :icon-post="iconPost"
      :is-loading="isLoading"
      :class-list="classList"
      :disabled="disabled"
      :lower-case="lowerCaseMode"
      @update:model-value="update"
      @validate="validate"
      @enter="enter"
      @keydown="emit('onkeydown')"
      @blur="() => emit('blur', { id: id })"
    />
    <CustomPassword
      v-if="inputType === CustomInputType.Password && !localLoad"
      :model-value="inputValueLocal"
      :placeholder="placeholder"
      :valid="v$.$errors.length === 0"
      :tooltip="tooltip"
      :label="label"
      :class-list="classList"
      @update:model-value="update"
      @validate="validate"
      @enter="enter"
    />
    <CustomNumber
      v-if="inputType === CustomInputType.Number && !localLoad"
      :model-value="inputValueLocal"
      :placeholder="placeholder"
      :valid="v$.$errors.length === 0"
      :tooltip="tooltip"
      :label="label"
      :class-list="classList"
      :step="props.validationObject?.step || 1"
      :min="props.validationObject?.minVal"
      :max="props.validationObject?.maxVal"
      :disabled="disabled"
      @update:model-value="update"
      @validate="validate"
    />
    <CustomTextarea
      v-if="inputType === CustomInputType.Textarea && !localLoad"
      :model-value="inputValueLocal"
      :placeholder="placeholder"
      :valid="v$.$errors.length === 0"
      :tooltip="tooltip"
      :label="label"
      :disabled="disabled"
      :row-count="rowCount"
      :show-character-count="showCharacterCount"
      @update:model-value="update"
      @validate="validate"
    />
    <CustomRadio
      v-if="inputType === CustomInputType.RadioGroup && !localLoad"
      :model-value="inputValueLocal"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :layout="layout"
      :label-position="labelPosition"
      :separator="separator"
      :radio-group="radioGroup"
      :group-custom-id="groupCustomId"
      :initial-width="initialWidth"
      @update:model-value="update"
      @validate="validate"
      @radio-change="(val) => emit('radioChange', val)"
    />
    <CustomCheckbox
      v-if="inputType === CustomInputType.CheckBoxGroup && !localLoad"
      :model-value="inputValueLocal"
      :placeholder="placeholder"
      :label-position="labelPosition"
      :tooltip="tooltip"
      :layout="layout"
      :separator="separator"
      :disabled="disabled"
      :valid="v$.$errors.length === 0"
      :label-class="labelClass"
      @update:model-value="update"
      @validate="validate"
    />
    <CustomMultiselect
      v-if="inputType === CustomInputType.Multiselect && !localLoad"
      :id="customId"
      :model-value="inputValueLocal"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :layout="layout"
      :close-on-select="closeOnSelect"
      :options="options"
      :label-key="labelKey"
      :label-key-with-id="labelKeyWithId"
      :mode="mode"
      :max="max"
      :multiselect-type="multiselectType"
      :async="async"
      :async-prop="asyncProp"
      :spread="spread"
      :value-prop="valueProp"
      :track-by="trackBy"
      :delay="delay"
      :limit="limit"
      :resolve-on-load="resolveOnLoad"
      :can-deselect="canDeselect"
      :can-clear="canClear"
      :infinite="infinite"
      :object="object"
      :min-chars="minChars"
      :searchable="searchable"
      :create-option="createOption"
      :valid="v$.$errors.length === 0"
      :disabled="disabled"
      :translate="translate"
      :valid-tag="validTag"
      :clear-on-select="clearOnSelect"
      :clear-on-blur="clearOnBlur"
      :filter-results="filterResults"
      :is-rounded="isRounded"
      :is-always-display-placeholder="isAlwaysDisplayPlaceholder"
      :class-list="classList"
      :can-create-folder="canCreateFolder"
      :actions="actions"
      :before-list-action="beforeListAction"
      @update:model-value="update"
      @validate="validate"
      @select="select"
      @clear="clear"
      @open="open"
      @close="close"
    />
    <DatePicker
      v-if="inputType === CustomInputType.Date && !localLoad"
      :model-value="inputValueLocal"
      :valid="v$.$errors.length === 0"
      :align="align"
      :time-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
      @update:model-value="update"
      @validate="validate"
    />
    <div v-if="showErrorMessage" :class="['error-container', ...getErrorClassList()]">
      <div :class="{ empty: v$.inputValueLocal.$errors.length === 0 }">{{ v$.inputValueLocal.$errors.length > 0 ? v$.inputValueLocal.$errors[0].$message : '' }}</div>
    </div>
  </div>
</template>
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable @typescript-eslint/no-explicit-any-->

<script setup lang="ts">
import { ref, onMounted, onUnmounted, reactive, computed, watch } from 'vue'
import { CustomInputType, ValidationParams, RadioGroupInput, InputLayout, InputIconActionType, CustomErrorMessages, ValidationObject } from '@/interfaces'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, minValue, maxValue, alphaNum, numeric, integer, email, url, sameAs, helpers } from '@vuelidate/validators'
import { uid } from 'uid'
import * as CustomValidators from '@/data/models/inputOptions/customValidators'
import CustomCheckbox from '@/components/common/custom-inputs/CustomCheckbox.vue'
import CustomNumber from '@/components/common/custom-inputs/CustomNumber.vue'
import CustomRadio from '@/components/common/custom-inputs/CustomRadio.vue'
import CustomString from '@/components/common/custom-inputs/CustomString.vue'
import CustomTextarea from '@/components/common/custom-inputs/CustomTextarea.vue'
import CustomMultiselect from '@/components/common/custom-inputs/CustomMultiselect.vue'
import DatePicker from '@/components/common/custom-inputs/DatePicker.vue'
import CustomPassword from './CustomPassword.vue'
import { useI18n } from 'vue-i18n'
import { TRUVID_MIN_DATE } from '@/services/constants'
import TextIconTooltip from '@/components/common/tooltips/TextIconTooltip.vue'
import { LabelPosition } from '@/components/common/custom-inputs/types'
import { Placement } from '@floating-ui/vue'

// used to provide unique ID to input component
const customId = ref(uid())
const localLoad = ref(true)
const timer = ref(null)
const id = uid()
const isAsyncPending = ref(false)
// local input value that is used for validation
const inputValueLocal = ref(null)
// (optional) props that define the input
const props = withDefaults(
  defineProps<{
    // CustomInputType enum
    inputType: string
    // input placeholder
    placeholder?: string
    // input label
    label?: string
    // input secondary label (on the right)
    secondaryLabel?: string
    // add asteriks symbol next to label
    asteriks?: boolean
    // input description - shown between label and input
    description?: string
    // input value
    modelValue: string | number | boolean | object | null | undefined
    // object containing validation rules, interface is ValidationParams
    validationObject?: ValidationParams
    // classes used for wrapper div (custom-input)
    containerClassList?: string[] | null
    // classes used for error div (error-container)
    containerErrorClassList?: string[] | null
    // passed on classes for inputs
    classList?: string[] | null
    // used when the value of this input needs to be the same as some other provided value -> pass in the value of the other input
    // defines the loading state
    isLoading?: boolean | null
    // sameAs?: string | number | object | null,
    // the message that will be display if 'sameAs' validation is not correct -> values do not match
    // sameAsMessage?: string | null,
    // the message that will be display if 'required' validation is not correct (missing/empty value)
    requiredMessage?: string | null
    // radio input definition array
    radioGroup?: RadioGroupInput[] | null
    // custom radio group id, used to enable linking separate CI radio groups into once group
    groupCustomId?: string | null
    // checkbox input separator - line to split inputs
    separator?: boolean | null
    // used for checkbox and radiogroup input type, interface is InputLayout
    layout?: InputLayout
    // used to define where the laber is located relative to the input for checkbox in radiogroup (before/after input)
    labelPosition?: LabelPosition | null
    // if input label should have tooltip, add message as this prop
    tooltip?: string | null
    infoTooltipPlacement?: Placement
    // color the tooltip icon in red in case of errors
    showTooltipError?: boolean | null
    // textarea input number of initial rows / height of input
    rowCount?: number | undefined
    // show character counter - gets the max character length
    showCharacterCount?: number
    // string input type can have pre and post icon (left/right icon)
    iconPre?: string | null
    iconPost?: string | null
    // the icon will perform the action passed as iconAction, currently implemented as enum InputIconActionType
    iconAction?: InputIconActionType | null
    // used for multiselect input, the initial options that can be passed in
    // FOLLOWING ARE multiselect (MS) properties
    // if options should be async, pass in empty array ([])
    options?: any
    // if options are objects, the labelKey will define the object key to be used as the label in the dropdown
    labelKey?: string | null
    // add the object ID to the label
    labelKeyWithId?: boolean | null
    // the multiselect mode, we currently use single and tags for all cases in the app
    mode?: 'single' | 'multiple' | 'tags'
    // MS prop, should the dropdown close after a value is selected
    closeOnSelect?: boolean | null
    // MS prop, should the dropdown be searchable, avoid using in 'single' mode, because the search input overlaps with selected value
    searchable?: boolean | null
    // MS prop, when in 'tags' mode and search is enabled, if a search value is not found, clicking on the searched value can create a new option -> we use this for tags
    createOption?: boolean | null
    // MS prop, should the dropdown have infinite scroll enabled, works with 'limit' prop
    infinite?: boolean | null
    // MS prop, limit the number of options per page in infinite scroll
    limit?: number | null
    // MS prop, the required minimal number of search query length to trigger search
    minChars?: number | null
    // MS prop, max number of selected options
    max?: number | null
    // MS prop for items type of selected options
    multiselectType?: string | null
    // MS prop, function definition that is called for async calls
    async?: ((...args: any[]) => any) | null
    // MS prop, async function params -> passed as array of params, so called function needs to handle this array
    asyncProp?: any | null
    // MS prop, when an async call returns values, sometimes the are wrapped as (res.categories), the 'spread' tells us which object key we need to use as value (in this case categories)
    spread?: string | null
    // MS prop, default value property is 'value', but if objects don't contain this property as value, 'valueProp' will define which key to use (e.i. 'id')
    valueProp?: string | null
    // MS prop, determines if the dropdown should store the value as the whole object or just the element under 'valueProp' key
    object?: boolean | null
    // MS prop, the 'search' is performed on the options' 'label' by default, if the object does not contain the 'label' key, we can define the 'search' key with 'trackBy' param
    trackBy?: string | string[] | null
    // MS delay search prop
    delay?: number | null
    // MS prop, should the dropdown perform the async call on load or not
    resolveOnLoad?: boolean | null
    // MS prop, can user deselect the value
    canDeselect?: boolean | null
    // MS prop, search cleared when value selected
    clearOnSelect?: boolean | null
    // MS prop, search cleared when input blurred
    clearOnBlur?: boolean | null
    // MS prop, can user clear the value
    canClear?: boolean | null
    // MS prop, handle filtering manually in async call (api call parameters)
    filterResults?: boolean | null
    // MS prop, validate the added tag value before adding it
    validTag?: ((...args: any[]) => any) | null
    // MS prop - keep the placeholder even after choosing a value
    isAlwaysDisplayPlaceholder?: boolean
    // define translation substring to enable translation of options (supposed the translation are available)
    translate?: string | null
    // disable input
    disabled?: boolean | null
    // should error messages be displayed
    showErrorMessage?: boolean | null
    // object containing custom error messages for each error validation type
    customErrorMessages?: CustomErrorMessages | null
    // should force lower case for string input
    lowerCaseMode?: boolean | null
    // datepicker aligment
    align?: string | null
    labelClass?: string
    // for multiSelect - makes rounded input container
    isRounded?: boolean
    //initial width for radio buttons
    initialWidth?: boolean
    //can create folders
    canCreateFolder?: boolean | null
    //currenlty only for multiselect
    actions?: { title: string; action: () => void }[]
    beforeListAction?: (options: any[]) => any[]
  }>(),
  {
    showErrorMessage: true,
    labelPosition: LabelPosition.POST,
    placeholder: '',
    separator: false,
    filterResults: true,
    async: undefined,
    lowerCaseMode: false
  }
)
const { t } = useI18n()
const errorMessages = ref({
  required: t('validation-value-required', { type: props.label ? props.label : t('validation-value') }),
  minLen: t('validation-value-min-len', { value: props.validationObject?.minLen }),
  maxLen: t('validation-value-max-len', { value: props.validationObject?.maxLen }),
  maxVal: t('validation-value-max-val', { value: props.validationObject?.maxVal }),
  minVal: t('validation-value-min-val', { value: props.validationObject?.minVal }),
  alphaNum: t('validation-value-aplha-num'),
  numeric: t('validation-value-numeric'),
  integer: t('validation-value-integer'),
  email: t('validation-value-email'),
  url: t('validation-value-url'),
  domain: t('validation-value-domain'),
  patternValidation: t('validation-value-pattern-validation', { pattern: '' }),
  checkboxN: t('validation-value-checkbox-n', {
    n: props.validationObject?.checkboxN,
    type: props.validationObject?.checkboxN > 1 ? t('validation-value-checkbox-multi') : t('validation-value-checkbox-one')
  }),
  multiN: t('validation-value-multi-n', {
    n: props.validationObject?.multiN,
    type: props.validationObject?.multiN > 1 ? t('validation-value-multi-n-multi') : t('validation-value-multi-n-one')
  }),
  sameAs: t('validation-value-same-as'),
  notSameAs: t('validation-value-not-same-as'),
  validationFunc: t('validation-value-validation-func'),
  date: t('validation-value-date'),
  isValidStringDate: t('datepicker-invalid-date-string'),
  isValidStringDateTodayOrOlder: t('datepicker-date-today-or-older'),
  isValidStringDateLaterThanMinDate:
    t('datepicker-date-later-than-mindate') +
    ' ' +
    `${TRUVID_MIN_DATE.getDate().toString().padStart(2, 0)}/${(TRUVID_MIN_DATE.getMonth() + 1).toString().padStart(2, 0)}/${TRUVID_MIN_DATE.getFullYear()}`
})
const computedErrorMessage = (type) => {
  if (props.customErrorMessages && props.customErrorMessages[type]) return props.customErrorMessages[type]
  return errorMessages.value[type]
}

onMounted(() => {
  localLoad.value = true
  inputValueLocal.value = props.modelValue
  localLoad.value = false
  emit('change', { value: props.modelValue, valid: !v$.value.$invalid, id: id })
})

onUnmounted(() => {
  emit('change', { value: props.modelValue, valid: true, id: id })
})

watch(
  () => props.modelValue,
  () => {
    inputValueLocal.value = props.modelValue
    if (!props.validationObject?.asyncValidation) {
      emit('change', { value: props.modelValue, valid: !v$.value.$invalid, id: id })
    }
  }
)

const debounce = (callback, timeout) => {
  const resolvers = []
  return async (...args) => {
    clearTimeout(timer.value)
    return new Promise((resolve) => {
      resolvers.push(resolve)
      timer.value = setTimeout(async () => {
        const result = await callback.apply(this, args)
        resolvers.map((resolver) => resolver(result))
        setTimeout(async () => {
          await validateFromDebounce()
        }, timeout)
      }, timeout)
    })
  }
}
// validator state, defines which variable to validate
const state = reactive({
  inputValueLocal: inputValueLocal,
  validationObject: {}
})
// validator rules, built dinamically based on validationObject properties
const rules = computed(() => {
  const localRules = {
    inputValueLocal: {}
  }
  if (props.validationObject) {
    // required validator
    localRules.inputValueLocal = {}
    if (props.validationObject.required) {
      localRules.inputValueLocal['required'] = helpers.withMessage(computedErrorMessage('required'), required)
    }
    // min string length validator
    if (props.validationObject.minLen !== undefined) {
      localRules.inputValueLocal['minLen'] = helpers.withMessage(computedErrorMessage('minLen'), minLength(props.validationObject.minLen))
    }
    // max string length validator
    if (props.validationObject.maxLen !== undefined) {
      localRules.inputValueLocal['maxLen'] = helpers.withMessage(computedErrorMessage('maxLen'), maxLength(props.validationObject.maxLen))
    }
    if (props.validationObject.minVal !== undefined) {
      localRules.inputValueLocal['minVal'] = helpers.withMessage(computedErrorMessage('minVal'), minValue(props.validationObject.minVal))
    }
    // max number value validator
    if (props.validationObject.maxVal !== undefined) {
      localRules.inputValueLocal['maxVal'] = helpers.withMessage(computedErrorMessage('maxVal'), maxValue(props.validationObject.maxVal))
    }
    // letters, numbers string value validator
    if (props.validationObject.alphaNum) {
      localRules.inputValueLocal['alphaNum'] = helpers.withMessage(computedErrorMessage('alphaNum'), alphaNum)
    }
    // numbers string value validator
    if (props.validationObject.numeric) {
      localRules.inputValueLocal['numeric'] = helpers.withMessage(computedErrorMessage('numeric'), numeric)
    }
    // integer number value validator
    if (props.validationObject.integer) {
      localRules.inputValueLocal['integer'] = helpers.withMessage(computedErrorMessage('integer'), integer)
    }
    // email string value validator
    if (props.validationObject.email) {
      localRules.inputValueLocal['email'] = helpers.withMessage(computedErrorMessage('email'), email)
    }
    // url string value validator - accepts http, https, ftp protocols
    if (props.validationObject.url) {
      localRules.inputValueLocal['url'] = helpers.withMessage(computedErrorMessage('url'), url)
    }
    if (props.validationObject.domain) {
      localRules.inputValueLocal['domain'] = helpers.withMessage(computedErrorMessage('domain'), CustomValidators.isValidDomainPattern)
    }
    // checks the provided value agains another value for exact match
    if (props.validationObject.sameAs) {
      localRules.inputValueLocal['sameAs'] = helpers.withMessage(computedErrorMessage('sameAs'), sameAs(props.validationObject.sameAs))
    }
    if (props.validationObject.notSameAs) {
      localRules.inputValueLocal['notSameAs'] = helpers.withMessage(computedErrorMessage('notSameAs'), CustomValidators.notSameAs(props.validationObject.notSameAs))
    }
    // checkbox validator, at least N boxes checked
    if (props.validationObject.checkboxN > 0) {
      localRules.inputValueLocal['atLeastNCheckbox'] = helpers.withMessage(computedErrorMessage('checkboxN'), CustomValidators.atLeastNCheckbox(props.validationObject.checkboxN))
    }
    // multiselect validator, at least N options selected
    if (props.validationObject.multiN > 0) {
      localRules.inputValueLocal['atLeastNOptions'] = helpers.withMessage(computedErrorMessage('multiN'), CustomValidators.atLeastNOptions(props.validationObject.multiN))
    }
    if (props.validationObject.isValidStringDate === true) {
      localRules.inputValueLocal['isValidStringDate'] = helpers.withMessage(computedErrorMessage('isValidStringDate'), CustomValidators.isValidStringDate)
    }
    if (props.validationObject.isValidStringDateTodayOrOlder === true) {
      localRules.inputValueLocal['isValidStringDateTodayOrOlder'] = helpers.withMessage(
        computedErrorMessage('isValidStringDateTodayOrOlder'),
        CustomValidators.isValidStringDateTodayOrOlder
      )
    }
    if (props.validationObject.isValidStringDateLaterThanMinDate === true) {
      localRules.inputValueLocal['isValidStringDateLaterThanMinDate'] = helpers.withMessage(
        computedErrorMessage('isValidStringDateLaterThanMinDate'),
        CustomValidators.isValidStringDateLaterThanMinDate
      )
    }
    if (props.validationObject.asyncValidation) {
      localRules.inputValueLocal['notSameAs'] = helpers.withMessage(computedErrorMessage('notSameAs'), helpers.withAsync(debounce(props.validationObject.asyncValidation, 1000)))
    }
    // regex pattern validation
    if (props.validationObject.patternValidation) {
      const regexValidator = helpers.regex(props.validationObject.patternValidation)
      localRules.inputValueLocal['patternValidation'] = helpers.withMessage(computedErrorMessage('patternValidation'), regexValidator)
    }
    // date validation - added automagically to Datepicker type input
    if (props.inputType === CustomInputType.Date) {
      localRules.inputValueLocal['dateValidation'] = helpers.withMessage(computedErrorMessage('date'), CustomValidators.isValidDate)
    }
    if (props.validationObject.customBackendValidationError) {
      localRules.inputValueLocal['customBackendValidationError'] = helpers.withMessage(props.customErrorMessages['customBackendValidationError'], () => false)
    }
  }
  return localRules
})

// validatior init
const v$ = useVuelidate(rules, state)
const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | boolean | object | null): void
  // for testing
  // not final definition, subject to change
  (e: 'iconActionEmit', value: string): void
  (e: 'change', value: ValidationObject): void
  (e: 'select', value: any): void
  (e: 'clear'): void
  (e: 'open'): void
  (e: 'close'): void
  (e: 'radioChange', value: string): void
  (e: 'openFolderModal'): void
  (e: 'enter'): void
  (e: 'onkeydown'): void
  (e: 'blur', id: number | string | object | null): void
}>()

async function isUpdateAsyncField(val) {
  isAsyncPending.value = true
  emit('change', { value: val, valid: false, id: id })
  inputValueLocal.value = val
  emit('update:modelValue', inputValueLocal.value)
  setTimeout(async () => {
    v$.value.$commit()
  }, 300)
}

// function call to update the model value state
async function update(val) {
  if (props.validationObject?.asyncValidation) {
    await isUpdateAsyncField(val)
  } else {
    inputValueLocal.value = val
    emit('update:modelValue', inputValueLocal.value)
    emit('change', { value: val, valid: !v$.value.$invalid, id: id })
  }
}

// function to trigger validator's validation
function validate() {
  return v$.value.$validate()
}

async function validateFromDebounce() {
  await v$.value.$validate()
  emit('change', { value: inputValueLocal.value, valid: !v$.value.$invalid && !v$.value.$pending, id: id })
  isAsyncPending.value = false
}

function enter() {
  emit('enter')
}

function select(event) {
  emit('select', { value: event })
}

function clear() {
  emit('clear')
}

function open() {
  emit('open')
}

function close() {
  emit('close')
}

function getClassList(): string[] {
  if (!props.containerClassList) return []
  return props.containerClassList
}

function getErrorClassList(): string[] {
  if (!props.containerErrorClassList) return []
  return props.containerErrorClassList
}

function getIsAsyncPending() {
  return isAsyncPending.value
}

defineExpose({
  validate,
  getIsAsyncPending,
  id,
  inputValueLocal,
  rules
})
</script>

<style scoped lang="scss">
.style-font {
  max-width: 30vw;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-container {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #ff0000;
  margin: 2px 0;
  position: absolute;
  top: 100%;
  white-space: nowrap;

  .empty {
    height: 19px;
  }

  &.wp-200 {
    width: 200px;
  }

  &.wp-300 {
    width: 300px;
  }

  &.wp-max-content {
    width: max-content;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.input-label {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #4c4c66;
  width: fit-content;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  font-weight: 500;

  .tooltip {
    margin-left: 8px !important;

    img.error {
      filter: brightness(0) saturate(100%) invert(32%) sepia(92%) saturate(7419%) hue-rotate(355deg) brightness(97%) contrast(118%);
    }
  }
}

.secondary-label {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
}

.description-container {
  margin: 8px 0;
  color: $disabledText;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
</style>
