<template>
  <div>
    <div :class="{ 'ci-container': true, multiselect: true, disabled, ...getClassList() }">
      <div class="input-container ci-multiselect" :class="{ error: !valid, 'is-rounded': isRounded }" :for="customId">
        <Multiselect
          v-if="loaded"
          :id="id"
          ref="selectRef"
          v-model="inputValueLocal"
          :mode="mode"
          :placeholder="setPlaceholder() ? placeholder : isMultiselection ? t('placeholder-type-to-search') : ''"
          :close-on-select="closeOnSelect"
          :object="object"
          :options="handleOptions"
          :resolve-on-load="resolveOnLoad"
          :delay="delay"
          :label="labelKey"
          :searchable="searchable"
          :create-option="createOption"
          :infinite="infinite"
          :limit="limit"
          :max="max"
          :value-prop="valueProp"
          :track-by="trackBy"
          :can-deselect="canDeselect"
          :can-clear="canClear"
          :min-chars="minChars"
          :filterResults="filterResults"
          :no-options-text="noOptionsTextComputed"
          :disabled="disabled"
          :clear-on-blur="clearOnBlur"
          :clear-on-select="clearOnSelect"
          :attrs="{
            disabled: valueSize >= max,
            autocomplete: off
          }"
          @select="handleEvents"
          @deselect="
            (event) => {
              enableOptions()
              handleEvents(event)
            }
          "
          @close="validate"
          @open="open"
          @clear="clear"
          @create="createTag"
        >
          <template #nooptions>
            <div class="multiselect-no-options">
              {{ noOptionsTextComputed }}
            </div>
          </template>
          <template #noresults>
            <div class="multiselect-no-options">
              {{ noOptionsTextComputed }}
            </div>
          </template>
          <template #beforelist>
            <button v-for="(actionObj, index) in props.actions" :key="index" :class="actionObj.buttonClass || 'multiselect-action-button'" @click="triggerAction(actionObj.action)">
              {{ actionObj.title }}
            </button>
            <div v-if="!showOptionsParam" class="multiselect-no-options">
              {{ noOptionsTextComputed }}
            </div>
          </template>
          <template v-if="props.isAlwaysDisplayPlaceholder" #singlelabel>
            <div class="single-placeholder">
              {{ placeholder }}
            </div>
          </template>
        </Multiselect>
      </div>
    </div>
  </div>
</template>

<!-- eslint-disable @typescript-eslint/no-explicit-any-->
<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { uid } from 'uid'
import Multiselect from '@vueform/multiselect'
import { useI18n } from 'vue-i18n'
import { getGMTOffset } from '@/services/utils'

const { t } = useI18n()
const customId = ref(uid())
const selectRef = ref(null)
const loaded = ref(false)
const off = ref('off')
const inputValueLocal = ref(null)
const inputOptionsLocal = ref([])
const allOptions = ref([])
const showOptionsParam = ref(true)
const isMultiselection = computed(() => {
  return (props.options.length > 1 || props.async) && !props.placeholder
})
const valueSize = computed(() => {
  return inputValueLocal.value?.length
})
const noOptionsTextComputed = computed(() => {
  return props.max && props.max <= valueSize.value
    ? t('multiselect-max-options-text', { max: props.max, type: props.multiselectType ? props.multiselectType : t('items') })
    : t('multiselect-no-options-text')
})
const props = withDefaults(
  defineProps<{
    id: string
    placeholder: string
    label?: string
    valid?: boolean
    modelValue: string | number | boolean | object | null
    options: any[]
    async?: ((...args: any[]) => any) | null
    asyncProp?: any | null
    labelKey?: string | null
    labelKeyWithId?: boolean | null
    page?: number | null
    pages?: number | null
    mode: 'single' | 'multiple' | 'tags'
    closeOnSelect?: boolean | null
    searchable?: boolean | null
    createOption?: boolean | null
    infinite?: boolean | null
    limit?: number | null
    max?: number | null
    multiselectType?: string | null
    delay?: number | null
    minChars?: number | null
    spread?: string | null
    valueProp?: string | null
    object?: boolean | null
    trackBy?: string | string[] | null
    resolveOnLoad?: boolean | null
    canDeselect?: boolean | null
    canClear?: boolean | null
    disabled?: boolean | null
    translate?: string | null
    clearOnSelect?: boolean | null
    clearOnBlur?: boolean | null
    filterResults?: boolean | null
    classList?: string[] | null
    validTag?: ((...args: any[]) => any) | null
    isRounded?: boolean | null
    isAlwaysDisplayPlaceholder?: boolean
    canCreateFolder?: boolean | null
    actions?: { title: string; buttonClass: string; action: () => void }[]
    beforeListAction?: (options: any[]) => any[]
  }>(),
  {
    filterResults: true,
    async: undefined,
    delay: 700
  }
)

function triggerAction(action: () => void) {
  action()
}

onMounted(() => {
  inputValueLocal.value = props.modelValue
  inputOptionsLocal.value = props.options
  loaded.value = true
})

async function optionsCallbackFn() {
  const currentOptions = selectRef.value.fo
  const option = currentOptions.find((option) => {
    return option.id === inputValueLocal.value
  })
  option ? selectRef.value.select(option) : null
}

watch(
  async () => props.modelValue,
  async () => {
    inputValueLocal.value = props.modelValue
    if (props.canCreateFolder) {
      selectRef.value.refreshOptions(optionsCallbackFn)
    }
    if (props.max && props.max > valueSize.value) {
      enableOptions(true)
    } else if (props.max && props.max <= valueSize.value) {
      disableOptions()
    }
  }
)

watch(
  () => props.options,
  () => {
    inputOptionsLocal.value = props.options
    if (!props.async && inputOptionsLocal.value?.length) loaded.value = true
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void
  (e: 'openFolderModal'): void
  (e: 'iconActionEmit', value: string): void
  (e: 'select', value: any): void
  (e: 'validate'): void
  (e: 'clear'): void
  (e: 'open'): void
  (e: 'close'): void
}>()

function handleEvents(event: any) {
  if (props.max && props.max <= valueSize.value) {
    disableOptions()
  }
  emit('update:modelValue', inputValueLocal.value)
  emit('select', event)
  validate()
}

function disableOptions() {
  inputOptionsLocal.value = []
  showOptionsParam.value = false
  if (props.async) selectRef.value.refreshOptions()
}

function enableOptions(force = false) {
  if (props.max - 1 !== valueSize.value && !force) return
  inputOptionsLocal.value = props.options
  showOptionsParam.value = true
  if (props.async) selectRef.value.refreshOptions()
}

async function handleOptions(query: string) {
  if (query === null) {
    query = ''
  }

  if (allOptions.value.length > 0 && query === '' && !props.canCreateFolder) {
    return allOptions.value
  }

  if (!props.async) {
    if (props.translate === 'translate' || props.translate === 'translate-tz') {
      inputOptionsLocal.value = inputOptionsLocal.value?.map((el) => {
        const obj = { ...el, translate: t(el[props.valueProp].toLowerCase()) }
        if (props.translate === 'translate-tz') {
          const timezone = obj.name
          const newGMTOffset = getGMTOffset(timezone)
          obj.translate = t(obj.name.toLowerCase(), { gmt: newGMTOffset })
        }
        return obj
      })
    }
    return inputOptionsLocal.value
  }
  if (props.max <= valueSize.value) {
    showOptionsParam.value = false
  }

  // api.service call
  let res = props.asyncProp ? (await props.async({ ...props.asyncProp, search: query }))[props.spread] : (await props.async(query))[props.spread]
  if (res && props.translate) {
    res = res.map((el) => {
      return { ...el, translate: t(props.translate + el[props.valueProp]) }
    })
  }

  if (res && props.labelKeyWithId) {
    res = res.map((el) => {
      return { ...el, [props.labelKey]: '(' + el[props.trackBy[0]] + ') ' + el[props.trackBy[1]] }
    })
  }

  let options: any[]
  if (res && inputOptionsLocal.value) {
    options = [...res, ...inputOptionsLocal.value]
  } else if (res) {
    options = [...res]
  } else if (inputOptionsLocal.value) {
    options = [...inputOptionsLocal.value]
  }
  if (allOptions.value.length === 0 && query === '') {
    allOptions.value = options
  }
  return options
}

function validate() {
  if (props.disabled) return
  emit('validate')
  emit('close')
}

function clear() {
  inputValueLocal.value = null
  emit('update:modelValue', inputValueLocal.value)
  enableOptions(true)
  validate()
  emit('clear')
}

function open() {
  emit('open')
}

function createTag(option) {
  option?.value?.split(';').forEach((el) => {
    if (props.validTag && !props.validTag(el)) {
      return false
    }
    const parsedOption = { name: el, value: el }
    const indexSearchObj = inputOptionsLocal.value.findIndex((el) => {
      return el.value === parsedOption.value
    })
    if (indexSearchObj > -1) return false
    inputOptionsLocal.value.push(parsedOption)
    if (!inputValueLocal.value) {
      inputValueLocal.value = []
    }
    if ((props.max && props.max > valueSize.value) || !props.max) inputValueLocal.value.push(parsedOption)
  })

  handleEvents(null)

  return false
}

function setPlaceholder() {
  return !inputValueLocal.value && props.placeholder
}

function getClassList(): { [key: string]: boolean } {
  const classes = {}
  if (!props.classList) return classes
  return { ...classes, ...Object.fromEntries(Object.entries(props.classList).map(([, v]) => [v, true])) }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
@import '@/styles/_variables.scss';

.is-rounded {
  border-radius: 40px;

  .multiselect {
    border-radius: 40px;
  }

  &.is-active {
    border-radius: 40px;
  }

  .multiselect-dropdown {
    z-index: $zIndex1;
    margin-bottom: -4px;
  }
}

.multiselect {
  min-height: $defaultInputHeight - 2 !important;
  border: none;
  box-shadow: none;
  background: $background;

  .transparent &,
  &.transparent {
    background: transparent;
  }

  &.white {
    background: $white;
    --ms-bg: $white;
  }

  .multiselect-wrapper {
    min-height: $defaultInputHeight - 2;
    border: none;
    box-shadow: none;

    .multiselect-placeholder {
      font-size: $fontSize;
      white-space: nowrap;
    }

    .multiselect-spinner {
      background-color: $blue;
    }

    .multiselect-single-label {
      padding-right: 0.875rem;
      width: 86%;
      font-size: 14px;
      color: #6f6e71;
    }
  }

  &.is-disabled {
    .multiselect-wrapper {
      cursor: default;
    }

    .multiselect-caret {
      display: none;
    }
  }

  &.is-active {
    border: none;
    box-shadow: none;
  }

  .multiselect-tag {
    font-size: 12px;
    border: 1px solid $inputBorder;
    background: #ffffff;
    color: #4c4c66;
    word-break: break-all;
    white-space: break-spaces;
  }

  .multiselect-tag-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    word-break: break-word;
  }

  .multiselect-option {
    display: block;
    word-wrap: break-word;
    white-space: break-spaces;
  }

  /* width */
  .multiselect-dropdown {
    &::-webkit-scrollbar {
      width: 4px;
      opacity: 0.2;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $scrollTrackColor;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $scrollBarColor;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $scrollBarColorHover;
    }

    .multiselect-no-options + .multiselect-options {
      display: none;
    }
  }

  .multiselect-tags-search-wrapper {
    &:has(input) {
      width: 100%;
      background-color: #ffffff;
    }

    input {
      padding: 0 5px;
    }
  }

  .multiselect-no-options {
    padding: var(--ms-option-py, 0.5rem) var(--ms-option-px, 0.75rem);
    cursor: default;
    font-weight: bold;

    &.empty {
      padding: 0;
    }
  }
}

.single-placeholder {
  width: 100%;
  margin-left: 14px;
  font-size: 12px;
  color: $placeholderMultiSelectSearchText;
}
</style>
