<template>
  <img v-if="profileImage" class="profile-pic" :class="{ size1: size === 1, hover: hover, 'is-safari-v15': isSafariVer15 }" :src="profileImage">
  <ProfileImageInitials
    v-else
    :initials="initials"
    :font-size="size"
    :initials-bg-color="randomColor"
    :class="{ size1: size === 1, hover: hover, 'is-safari-v15': isSafariVer15 }"
    class="profile-pic"
  />
</template>

<script setup lang="ts">
import { getRandomPastelColor, isSafariVersionOlderThanV16 } from '@/services/utils'
import { capitalize, computed } from 'vue'
import { useStore } from 'vuex'
import ProfileImageInitials from '@/components/common/profile-image/ProfileImageInitials.vue'
import { NOT_AVAILABLE } from '@/services/constants'

interface ProfileImage {
  size?: number
  hover?: boolean
  profileImage?: string
  scalable?: boolean
  customWidthHeight?: string
}

const props = withDefaults(defineProps<ProfileImage>(), {
  size: 2,
  customWidthHeight: '7.5rem'
})
const store = useStore()
const firstName = computed(() => store.state.user.user.first_name)
const lastName = computed(() => store.state.user.user.last_name)
const randomColor = computed(() => store.state.user.user.color ?? getRandomPastelColor())
const initials = computed(() =>
  [firstName.value, lastName.value && lastName.value !== NOT_AVAILABLE ? lastName.value : ''].map((name) => capitalize(name ?? '').slice(0, 1)).join('')
)
const isSafariVer15 = computed(() => isSafariVersionOlderThanV16())
</script>

<style lang="scss" scoped>
.profile-pic {
  display: block;
  position: relative;
  width: v-bind('props.customWidthHeight');
  height: v-bind('props.customWidthHeight');
  border-radius: 100%;
  outline: 6px solid #dadbe8;
  object-fit: cover;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: 0.5s;
  filter: brightness(1);

  &.is-safari-v15 {
    &::before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: 6px solid #dadbe8;
      border-radius: 100%;
    }
  }

  @media (max-width: $sm) {
    width: 70px;
    height: 70px;
  }

  &.size1 {
    outline: none;
    box-shadow: none;
    &.is-old-safari {
      &::before {
        content: none;
      }
    }
  }

  &.hover:hover {
    transform: scale(1.05);
  }
}
</style>
