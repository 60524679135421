<template>
  <div v-if="shouldShow" class="geo-restrictions" :class="{ pill: layout === 'pill', inversed: layout === 'inversed' }">
    <TeleportTooltip :max-width="maxWidth" placement="top-end" :text-length="unlimited ? 300 : 100">
      <div v-if="unlimited" class="d-flex flex-xs-align-center">
        <img :src="getIcon" alt="">
        {{ geoRestrictionsData.countryList }}
      </div>
      <div v-else class="d-flex flex-xs-align-center max-content">
        <img :src="getIcon" alt="">
        {{ shouldHaveTitle ? geoRestrictionsData.statusMessage : '' }}
      </div>
      <template #content>
        <div class="style-font">{{ geoRestrictionsData.countryList }}</div>
      </template>
    </TeleportTooltip>
  </div>
</template>

<script setup lang="ts">
import { getCountriesString } from '@/services/utils'
import { computed } from 'vue'
//images
import restrictedIcon from '@/assets/geo/restricted_icon.svg'
import restrictedIconWhite from '@/assets/geo/restricted_icon_white.svg'
import TeleportTooltip from './tooltips/TeleportTooltip.vue'

const props = defineProps({
  countriesAllow: null,
  countriesDisallow: null,
  maxWidth: {
    type: Number,
    default: 330
  },
  layout: null,
  unlimited: {
    type: Boolean,
    default: false
  }
})

const getIcon = computed(() => {
  switch (props.layout) {
    case 'pill':
      return restrictedIconWhite
    case 'inversed':
      return restrictedIcon
    default:
      return restrictedIcon
  }
})

const geoRestrictionsData = computed(() => getCountriesString(props.countriesAllow || [], props.countriesDisallow || []))
const shouldShow = computed(() => {
  return props.countriesAllow || props.countriesDisallow ? true : false
})
const shouldHaveTitle = computed(() => {
  return props.layout === 'pill' || props.layout === 'inversed' ? true : false
})
</script>

<style lang="scss" scoped>
.geo-restrictions {
  font-size: 12px;

  &.pill {
    background-color: $restrictionsBackground;
    color: $white;
    width: fit-content;
    border-radius: 4px;
    padding: 3px 8px;
  }

  &.inversed {
    color: $titleBlack;
    width: max-content;
    border-radius: 4px;
    padding: 3px 8px;
    border-radius: 4px;
    border: 1px solid $inversedGeoRestrictionsBorder;
    background: $white;
    align-content: center;
  }

  img {
    margin-right: 4px;
  }

  .max-content {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
  }
}
</style>
