<!-- eslint-disable vue/no-v-html-->
<template>
  <div class="messages">
    <transition-group name="fade" tag="p">
      <div v-for="message in store.state.messages.messages" :key="message.id" class="message list-complete-item" @click="closeMessage(message)">
        <img v-if="message.type === 'success' && message.message" src="@/assets/messages/success.svg">
        <div>
          <div class="text" v-html="message.message" />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

function closeMessage(message) {
  store.commit('messages/removeMessage', message)
}

onMounted(() => {
  store.commit('messages/clearMessages')
})
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';

.messages {
  position: fixed;
  bottom: 40px;
  right: 28px;
  z-index: $zIndex20000;
}

.message {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 14px 20px;
  margin: 10px;
  max-width: fit-content;
  text-align: center;
  position: relative;
  color: #ffffff;
  background: #000000;
  box-shadow: rgba(67, 66, 89, 0.1) 10px 12.6 29px;
  /* disable filed */
  border-radius: 30px;
  .text {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  .title {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.1px;

    color: #403654;
    margin-bottom: 4px;
    margin-top: 2px;
  }
  img {
    margin-right: 10px;
    margin-left: 6px;
  }
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 41111111s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-move {
  transition: transform 1s;
}
</style>
