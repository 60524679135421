<template>
  <div class="modal-footer">
    <div v-if="footerProps" :class="['buttons', buttonAlignmentClass]">
      <Button v-if="footerProps.buttonCancel" :variant="ButtonVariant.TERTIARY" :text="footerProps.buttonCancel" @click="handleCancel" />
      <Button
        v-if="footerProps.buttonConfirm" :variant="ButtonVariant.PRIMARY" :text="footerProps.buttonConfirm" :disabled="!footerProps.isConfirmValid"
        @click="handleConfirm"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/common/buttons/Button.vue'
import { ButtonVariant } from '@/styles/types'
import { CustomModalProps } from '@/interfaces'
import { computed } from 'vue'

const emit = defineEmits(['close-action'])

const props = defineProps<CustomModalProps>()

const buttonAlignmentClass = computed(() => {
  const hasCancel = !!props.footerProps?.buttonCancel
  const hasConfirm = !!props.footerProps?.buttonConfirm

  if (hasCancel && hasConfirm) {
    return 'two-buttons'
  } else {
    return 'single-button'
  }
})

async function handleConfirm() {
  if (typeof props.onConfirm === 'function') {
    await props.onConfirm({ ...props })
  }
}

async function handleCancel() {
  emit('close-action')
}
</script>

<style scoped>
.modal-footer {
  .buttons {
    margin-top: 1rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    &.two-buttons {
      justify-content: space-between;
    }

    &.single-button {
      justify-content: center;
      margin: 0;
    }
  }
}
</style>
