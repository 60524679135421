<template>
  <div class="modal-header">
    <img v-if="!store.state.modal.loading" class="close-x" src="@/assets/common/close.svg" @click.stop="emit('close-action')">
    <div v-if="headerProps">
      <div class="default-container">
        <img :src="headerProps.icon">
        <div class="text-container">
          <div class="text">
            {{ headerProps.text }}
          </div>
          <div class="sub-text">
            {{ headerProps.textSecondary }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { CustomModalProps } from '@/interfaces'

const store = useStore()
defineProps<CustomModalProps>()
const emit = defineEmits(['close-action'])
</script>

<style scoped>
.modal-header {
  .close-x {
    position: absolute;
    right: 16px;
    top: 24px;
    cursor: pointer;
  }
  .default-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    .text-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 396px;
      margin-bottom: 1rem;

      .text {
        font-style: normal;
        font-weight: 800;
        font-size: 21px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-wrap: nowrap;
      }

      .sub-text {
        text-align: center;
        color: #8a898c;
      }
    }
  }
}
</style>
